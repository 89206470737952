import { FormControl, Select, MenuItem, IconButton, SelectChangeEvent } from "@mui/material";
import { QuestionType, getQuestionTypeLabel } from "../../API/enums/questionType.enum";
import { FC } from "react";
import unfilter from '../../assets/images/unfilter.svg';

interface IProps {
    typeAr: string[];
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    isAnalytics?: boolean;
}

const FilterByQuestionType: FC<IProps> = ({ typeAr, setType, isAnalytics }) => {

    const handleChange = (event: SelectChangeEvent<typeof typeAr>) => {
        const {
            target: { value },
        } = event;
        setType(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    return (
        <FormControl variant='outlined' sx={{ width: '160px', display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', margin: '0 auto', justifyContent: 'center' }}>
            <Select
                value={typeAr}
                multiple
                onChange={handleChange}
                sx={{ width: '100px', height: '38px', background: '#e4e7eb', borderRadius: '7px' }}>
                {
                    !isAnalytics ?
                        Object.values(QuestionType).filter((type) => !isNaN(+type))
                            .map((type) =>
                                <MenuItem value={type} key={type}>
                                    {getQuestionTypeLabel(type as QuestionType)}
                                </MenuItem>
                            )
                        :
                        Object.values(QuestionType).filter((type) => !isNaN(+type)).splice(0,3)
                            .map((type) =>
                                <MenuItem value={type} key={type}>
                                    {getQuestionTypeLabel(type as QuestionType)}
                                </MenuItem>
                            )
                }
            </Select>
            <IconButton
                sx={{
                    width: '38px', height: '38px', background: '#e4e7eb', borderRadius: '7px',
                    opacity: typeAr.length === 0 ? .65 : 1, marginLeft: '16px'
                }}
                onClick={() => typeAr.length > 0 && setType([])}
            >
                <img src={unfilter} alt="" />
            </IconButton>
        </FormControl>
    )
}

export default FilterByQuestionType;