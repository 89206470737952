import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/common.interface';
import { IHelpRef, IHelpRefCreate, IHelpRefLookUp, IHelpRefSearch, IHelpRefUpdate} from '../interfaces/help-refs.interface';

export class HelpService {
    constructor(private api: ApiClient){}

    public async lookUp(){
        return this.api.get<SearchResult<IHelpRefLookUp>>('help-refs/lookup');
    }

    public async search(query?: IHelpRefSearch){
        return this.api.get<SearchResult<IHelpRef>>('help-refs', query);
    }

    public async downloadFile(fileName: string){
        return this.api.get('help-refs/' + fileName);
    }

    public getFileLink(fileName: string){
        return this.api.baseURL + 'help-refs/' + fileName;
    }

    public async create(payload: IHelpRefCreate){
        return this.api.post<IHelpRef>('help-refs', this.api.setFormData(payload));
    }

    public async update(id: string, payload: IHelpRefUpdate){
        return this.api.put<IHelpRef>('help-refs/' + id, this.api.setFormData(payload));
    }

    public async delete(id: string){
        return this.api.delete<void>('help-refs/' + id);
    }
}
const helpService = new HelpService(apiClient);

export default helpService;