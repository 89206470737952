import { Box, IconButton, TextField } from "@mui/material"
import { FC } from "react";
import unfilter from '../../assets/images/unfilter.svg';

interface IFindProps {
    findByName: string;
    setFindByName: React.Dispatch<React.SetStateAction<string>>;
    width: string;
    handleSearch?: (searchValue: string) => void
}

const FindByNameTextfield: FC<IFindProps> = ({ findByName, setFindByName, width, handleSearch }) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <TextField
                type="string"
                size="small"
                sx={{ background: '#e4e7eb', borderRadius: '7px', padding: 0, border: 'none', width: width }}
                variant="outlined"
                value={findByName}
                onChange={(e) => {
                    setFindByName(e.target.value)
                    handleSearch && handleSearch(e.target.value)
                }}
            />

            <IconButton
                sx={{
                    width: '38px', height: '38px', background: '#e4e7eb', borderRadius: '7px',
                    opacity: findByName.length === 0 ? .65 : 1, marginLeft: '16px'
                }}
                onClick={() => findByName.length > 0 && setFindByName('')}
            >
                <img src={unfilter} alt="" />
            </IconButton>
        </Box>
    )
}

export default FindByNameTextfield;