import { Checkbox, IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { FC, useState } from 'react';

import showIcon from '../../assets/images/show.svg';
import deleteIcon from '../../assets/images/delete.svg';
import crossIcon from '../../assets/images/cross.svg';
import acceptIcon from '../../assets/images/plus.svg';
import { IChoice } from '../../API/interfaces/choices.interface';

interface IProps {
    choice: IChoice;
    onUpdate: (payload: IChoice) => void;
    onDelete: (payload: IChoice) => void;
}

const AnswersItem: FC<IProps> = ({ choice, onUpdate, onDelete }) => {
    const [text, setText] = useState<string>(choice.text || '');
    const [isCorrect, setIsCorrect] = useState<boolean>(choice.isCorrect || false);
    const [isEditMode, setEditMode] = useState<boolean>(false);

    const resetValues = () => {
        setEditMode(false);
        setText(choice.text);
        setIsCorrect(choice.isCorrect || false);
    }

    const handleUpdate = () => {
        onUpdate({ ...choice, text, isCorrect });
        setEditMode(false);
    }

    const handleDelete = () => onDelete(choice);

    const disableValues = !(text)

    if (isEditMode) return (
        <TableRow sx={{background: '#018DCA33'}}>
            <TableCell align="center">
                <Checkbox checked={isCorrect} onChange={() => setIsCorrect(!isCorrect)} />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size='small'
                    fullWidth
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    multiline={true}
                    minRows={1}
                    maxRows={3}
                />
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={resetValues}> <img src={crossIcon} alt="X" /> </IconButton>
                <IconButton onClick={handleUpdate} disabled={disableValues}
                    sx={{ opacity: disableValues ? '.6' : '1' }}
                >
                    <img src={acceptIcon} alt="✓" />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    return (
        <TableRow>
            <TableCell align="left" >
                <Checkbox checked={isCorrect} disabled />
            </TableCell>
            <TableCell align="left">{choice.text}</TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(true)}><img src={showIcon} alt="показать" /></IconButton>
                <IconButton onClick={handleDelete}><img src={deleteIcon} alt="удалить" /></IconButton>
            </TableCell>
        </TableRow >
    )
}

export default AnswersItem;