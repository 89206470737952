import React, { createContext, useContext, useState } from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';

interface IContext {
    setVisible: React.Dispatch<React.SetStateAction<boolean>> | null;
}

const UIVisibleContext = createContext<IContext>({ setVisible: null });
export const useUIVisibleContext = () => {
    const {setVisible} = useContext(UIVisibleContext);
    return setVisible as React.Dispatch<React.SetStateAction<boolean>>;
};

function Layout() {
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(true);

    return (

        <div style={{ fontFamily: 'Roboto', maxWidth: visible? '95%':'100%', margin: visible? '0 auto 0 5%':'0' }}>
            {visible && <Sidebar open={open} setOpen={setOpen} />}
            <UIVisibleContext.Provider value={{ setVisible }}>
                <Outlet />
            </UIVisibleContext.Provider>
        </div>

    )
}

export default Layout;