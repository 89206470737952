import { Autocomplete, Button, Checkbox, FormControl, IconButton, MenuItem, Select, TableCell, TableRow, TextField } from '@mui/material';
import { FC, useState } from 'react';
import showIcon from '../../assets/images/show.svg';
import deleteIcon from '../../assets/images/delete.svg';
import crossIcon from '../../assets/images/cross.svg';
import acceptIcon from '../../assets/images/plus.svg';
import { Link } from 'react-router-dom';
import { IQuestion, IQuestionUpdate } from '../../API/interfaces/questions.interface';
import { IHelpRefLookUp } from '../../API/interfaces/help-refs.interface';
import { getQuestionTypeLabel, QuestionType } from '../../API/enums/questionType.enum';
import AutocompleteGroupRender from '../AutocompleteGroupRender';


interface IProps {
    question: IQuestion;
    helpRefs: IHelpRefLookUp[];
    onUpdate: (id: string, payload: IQuestionUpdate) => Promise<void>;
    onDelete: (id: string) => Promise<void>;
}

const QuestionsItem: FC<IProps> = ({ question, helpRefs, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);

    const [isVisible, setIsVisible] = useState(question.isVisible);
    const [type, setType] = useState(question.type);
    const [name, setName] = useState(question.name);
    const [helpRef, setHelpRef] = useState(helpRefs.find((hR) => hR.id === question.helpId) || null);

    const resetValues = () => {
        setEditMode(false);
        setIsVisible(question.isVisible);
        setType(question.type);
        setName(question.name);
        setHelpRef(helpRefs.find((hR) => hR.id === question.helpId) || null);
    }

    const handleUpdate = () => {
        onUpdate(question.id, {
            isVisible,
            type,
            name,
            helpId: helpRef?.id || null
        }).then(() => setEditMode(false));
    }

    const handleDelete = () => onDelete(question.id);

    const disableValues = !(name);

    const getQuestionTypeColor =
        question.type === 0 ? '#018DCA' :
            question.type === 1 ? '#7a7785' :
                question.type === 2 ? '#F46141CC' :
                    question.type === 3 ? 'transparent' : '#fff';

    const getQuestionTypeBorderColor =
        question.type === 0 ? '#transparent' :
            question.type === 1 ? '#transparent' :
                question.type === 2 ? '#transparent' :
                    question.type === 3 ? '#797979' : '#fff';


    if (isEditMode) return (
        <TableRow sx={{ verticalAlign: 'top', background: '#018DCA33' }}>
            <TableCell align="center">
                <Checkbox checked={isVisible} onChange={() => setIsVisible(!isVisible)} />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    multiline
                    maxRows={3}
                    minRows={1}
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <FormControl variant='outlined' size="small" fullWidth sx={{ background: '#fff', borderRadius: '7px' }}>
                    <Select value={type} onChange={(e) => setType(Number(e.target.value))} >
                        {
                            Object.values(QuestionType).filter((type) => !isNaN(+type))
                                .map((type) => <MenuItem value={type} key={type}>{getQuestionTypeLabel(type as QuestionType)}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <Autocomplete fullWidth
                    size='small'
                    disablePortal
                    options={helpRefs}
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    groupBy={(option) => option.section}
                    getOptionLabel={(option) => option.name}
                    renderGroup={AutocompleteGroupRender}
                    value={helpRef}
                    onChange={(e, value) => { setHelpRef(value) }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </TableCell>
            <TableCell align="center">
                <Link to={`/questions/${question.id}/answers`}>
                    <Button variant='outlined'>Перейти</Button>
                </Link>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={resetValues}> <img src={crossIcon} alt="X" /> </IconButton>
                <IconButton onClick={handleUpdate} disabled={disableValues}
                    sx={{ opacity: disableValues ? '.6' : '1' }}
                >
                    <img src={acceptIcon} alt="✓" />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    return (
        <TableRow>
            <TableCell align="center"><Checkbox checked={question.isVisible} disabled={true} /></TableCell>
            <TableCell align="center">
                <p className='ellipsis'>{question.name}</p>
            </TableCell>
            <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '97px' }}>
                <span
                    style={{
                        width: '16px', height: '16px',
                        borderRadius: '10px', background: getQuestionTypeColor, border: `1px solid ${getQuestionTypeBorderColor}`,
                        display: 'block', margin: '1px 9px 0 0'
                    }}></span>
                {getQuestionTypeLabel(question.type)}

            </TableCell>
            <TableCell align="center">
                <p className='ellipsis'>{helpRef?.name}</p>
            </TableCell>
            <TableCell align="center">
                <Link to={`/questions/${question.id}/answers`}>
                    <Button variant='outlined'>{question._count?.choices}</Button>
                </Link>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(true)}><img src={showIcon} alt="показать" /></IconButton>
                <IconButton onClick={handleDelete}><img src={deleteIcon} alt="удалить" /></IconButton>
            </TableCell>
        </TableRow>
    )
}

export default QuestionsItem;