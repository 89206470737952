import { useState, FC } from 'react';
import { Autocomplete, Button, IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { IHelpRef, IHelpRefUpdate } from '../../API/interfaces/help-refs.interface';
import { ISectionLookUp } from '../../API/interfaces/section.interface';
import helpService from '../../API/services/help.service';
import showIcon from '../../assets/images/show.svg';
import deleteIcon from '../../assets/images/delete.svg';
import crossIcon from '../../assets/images/cross.svg';
import acceptIcon from '../../assets/images/plus.svg';

interface IHelpRefItemProps {
    helpRef: IHelpRef;
    sections: ISectionLookUp[];
    onUpdate: (id: string, payload: IHelpRefUpdate) => Promise<void>
    onDelete: (id: string) => Promise<void>
}

const HelpRefItem: FC<IHelpRefItemProps> = ({ helpRef, sections, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState(false);

    const [section, setSection] = useState(sections.find((section) => (section.id === helpRef.sectionId)) || null);
    const [name, setName] = useState(helpRef.name);
    const [file, setFile] = useState<File | null>(null);

    const [error, setError] = useState('');

    const disableValues = !(section && name)


    const resetValues = () => {
        setEditMode(false);
        setSection(sections.find((section) => (section.id === helpRef.sectionId)) || null);
        setName(helpRef.name);
        setFile(null);
        setError('');
    }

    const handleUpdate = () => {
        onUpdate(helpRef.id, {
            name,
            sectionId: section!.id,
            ...file && { file }
        })
            .then(() => setEditMode(false))
            .catch((err) => {
                console.log({ ...err });
                setError("Файл слишком большой");
            });
    }

    const handleDelete = () => onDelete(helpRef.id);

    if (isEditMode) return (
        <TableRow sx={{ verticalAlign: 'top', background: '#018DCA33' }}>
            <TableCell align='center'>
                <Autocomplete fullWidth
                    size="small"
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    disablePortal
                    options={sections}
                    getOptionLabel={option => option.name}
                    value={section}
                    onChange={(e, value) => { setSection(value) }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </TableCell>
            <TableCell align="center" sx={{border: 'none'}}>
                <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={10}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required={true}
                />
            </TableCell>
            <TableCell align="center">
                <Button component="label" variant="outlined" size="large" fullWidth color={Boolean(error) ? 'error' : 'primary'}>
                    <span className="ellipsis">{"Загрузить"}</span>
                    <input type="file" onChange={(e) => {
                        if (e.target.files) {
                            setFile(e.target.files[0]);
                            setError('');
                        }
                    }} accept="application/pdf" hidden />
                </Button>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={resetValues}> <img src={crossIcon} alt="X" /> </IconButton>
                <IconButton onClick={handleUpdate} disabled={disableValues}
                sx={{opacity: disableValues? '.6':'1'}}
                >
                    <img src={acceptIcon} alt="✓" /> 
                </IconButton>
            </TableCell>
        </TableRow>
    )

    return (
        <TableRow>
            <TableCell align="center">
                <p className='ellipsis'>{section?.name}</p>
            </TableCell>
            <TableCell align="center">
                <p className='ellipsis'>{helpRef.name}</p>
            </TableCell>
            <TableCell align="center">
                {
                    helpRef.file && (
                        <Button component="a" variant="outlined" size="large" fullWidth href={helpService.getFileLink(helpRef.file)}>
                            <span className="ellipsis">{helpRef.file}</span>
                        </Button>
                    )
                }
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(true)}><img src={showIcon} alt="показать" /></IconButton>
                <IconButton onClick={handleDelete}><img src={deleteIcon} alt="удалить" /></IconButton>
            </TableCell>
        </TableRow>
    )
}

export default HelpRefItem;