import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/common.interface';
import { IChoice, IChoiceCreate, IChoiceSearch, IChoiceUpdate} from '../interfaces/choices.interface';

export class ChoicesService {
    constructor(private api: ApiClient){}

    public async search(query?: IChoiceSearch){
        return this.api.get<SearchResult<IChoice>>('choices', query);
    }

    public async create(payload: IChoiceCreate){
        return this.api.post<IChoice>('choices', payload);
    }

    public async update(id: string, payload: IChoiceUpdate){
        return this.api.put<IChoice>('choices/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('choices/' + id);
    }
}
const choicesService = new ChoicesService(apiClient)
export default choicesService;