import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import { Avatar, Button, ListItemButton, Stack } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useAuth } from '../Context/Auth';

import help from '../assets/images/help.svg';
import modules from '../assets/images/modules.svg';
import questions from '../assets/images/questions.svg';
import settings from '../assets/images/settings.svg';
import userPerformance from '../assets/images/user-performance.svg';
import users from '../assets/images/users.svg';
import { RoleEnum, getRoleLabel } from '../API/enums/Role.enum';

import left from '../assets/images/left.svg';
import right from '../assets/images/right.svg';
import userService from '../API/services/users.service';
import { IUser } from '../API/interfaces/users.interface';

const drawerWidth = 231;

const iconsSize = { width: 28, height: 28 };

const fontStyle = {
  paddingLeft: 24,
  lineHeight: '50px',
  color: '#1E1E1E'
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: 'ease',
    duration: '300ms',
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: 'ease',
    duration: '300ms',
  }),
  overflowX: 'hidden',
  width: '89px'
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    color: 'black',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface sidebarProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Sidebar({ open, setOpen }: sidebarProps) {
  const { isAdmin, isCurator, signOut } = useAuth();
  const [profile, setProfile] = React.useState<IUser>();

  let location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    userService.getProfile()
    .then(setProfile)
    .catch(console.log)
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>


      <AppBar position='fixed' open={open} style={{ background: 'transparent', boxShadow: 'none' }}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              position: 'absolute',
              top: '92vh',
              left: '1vw',
              ...(open && { display: 'none' }),
            }}
            style={{ position: 'relative' }}
          >
            {!open && <img src={right} alt='<' />}
          </IconButton>
          <IconButton sx={{
            position: 'absolute',
            top: '93.5vh', left: '-3vw'
          }}>
            {!open ? <></> : <img src={left} onClick={handleDrawerClose} alt='>' />}
          </IconButton>

        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader sx={{ height: '133px' }}>
          <Avatar sx={{ width: '60px', height: '60px', margin: '0 auto' }} />
          {open &&
            <Stack direction='column' sx={{ margin: '22px 15px 35px 19px' }}>
              <p style={{ fontSize: '20px', margin: '0', lineHeight: '24px' }}>{profile && profile?.firstName + ' ' + profile?.lastName}</p>
              <p style={{ fontSize: '14px', fontWeight: 300, margin: '0', lineHeight: '24px' }}>
                {getRoleLabel(profile? profile?.role: RoleEnum.EMPLOYEE)}
              </p>
              <Button variant='contained' onClick={signOut}>Выйти</Button>
            </Stack>
          }
        </DrawerHeader>
        <List>
          <Link to='/user-performance' style={{ textDecoration: 'none', color: '#000', height: '50px' }}>
            <ListItem style={{ padding: 0, background: location.pathname === '/user-performance' ? '#018DCA33' : '#fff' }}>
              <ListItemButton style={{ padding: '0 0 0 33px', height: '50px' }}>
                <img src={userPerformance} alt='help' />
                {open && <div style={fontStyle}>Аналитика</div>}
              </ListItemButton>
            </ListItem>
          </Link>
          {
            (isAdmin || isCurator) && (
              <Link to='/Users' style={{ textDecoration: 'none', color: '#000' }}>
                <ListItem style={{ padding: 0, background: location.pathname === '/Users' ? '#018DCA33' : '#fff', height: '50px' }}>
                  <ListItemButton style={{ padding: '0 0 0 33px', height: '50px' }}>
                    <img src={users} alt="users" />
                    {open && <div style={fontStyle}>Пользователи</div>}
                  </ListItemButton>
                </ListItem>
              </Link>
            )
          }
          {
            false && (
              <Link to='/roles' style={{ textDecoration: 'none', color: '#000' }}>
                <ListItem style={{ padding: 0, height: '50px' }}>
                  <ListItemButton style={{ padding: '0 0 0 33px', height: '50px' }}>
                    <EngineeringIcon sx={iconsSize} />
                    {open && <div style={fontStyle}>Роли</div>}
                  </ListItemButton>
                </ListItem>
              </Link>
            )
          }
          {
            isAdmin && (
              <Link to='/modules' style={{ textDecoration: 'none', color: '#000' }}>
                <ListItem style={{ padding: 0, background: location.pathname === '/modules' ? '#018DCA33' : '#fff', height: '50px' }}>
                  <ListItemButton style={{ padding: '0 0 0 33px', height: '50px' }}>
                    <img src={modules} alt="modules" />
                    {open && <div style={fontStyle}>Модули</div>}
                  </ListItemButton>
                </ListItem>
              </Link>
            )
          }
          {
            isAdmin && (
              <Link to='/questions' style={{ textDecoration: 'none', color: '#000' }}>
                <ListItem style={{ padding: 0, background: location.pathname === '/questions' ? '#018DCA33' : '#fff', height: '50px' }}>
                  <ListItemButton style={{ padding: '0 0 0 33px', height: '50px' }}>
                    <img src={questions} alt="questions" />
                    {open && <div style={fontStyle}>Вопросы</div>}
                  </ListItemButton>
                </ListItem>
              </Link>
            )
          }
          {
            (isAdmin || isCurator) && (
              <Link to='/help' style={{ textDecoration: 'none', color: '#000' }}>
                <ListItem style={{ padding: 0, background: location.pathname === '/help' ? '#018DCA33' : '#fff', height: '50px' }}>
                  <ListItemButton style={{ padding: '0 0 0 33px', height: '50px' }}>
                    <img src={help} alt="help" />
                    {open && <div style={fontStyle}>Справки</div>}
                  </ListItemButton>
                </ListItem>
              </Link>
            )
          }
          {
            isAdmin && (
              <Link to='/settings' style={{ textDecoration: 'none', color: '#000' }}>
                <ListItem style={{ padding: 0, background: location.pathname === '/settings' ? '#018DCA33' : '#fff', height: '50px' }}>
                  <ListItemButton style={{ padding: '0 0 0 33px', height: '50px' }}>
                    <img src={settings} alt="settings" />
                    {open && <div style={fontStyle}>Настройки</div>}
                  </ListItemButton>
                </ListItem>
              </Link>
            )
          }
        </List>
      </Drawer>
    </Box>
  );
}
