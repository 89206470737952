import { FormControl, IconButton, MenuItem, Select } from "@mui/material"
import { FC } from "react";
import unfilter from '../../assets/images/unfilter.svg';
import { RoleEnum, getRoleLabel } from "../../API/enums/Role.enum";

interface IRoleFilterProps {
    role: string;
    setRole: React.Dispatch<React.SetStateAction<string>>;
}

const FilterByRole: FC<IRoleFilterProps> = ({ role, setRole }) => {
    return (
        <FormControl variant='outlined' sx={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', margin: '0 auto', justifyContent: 'center' }}>
            <Select sx={{ width: '100px', height: '38px', background: '#e4e7eb', borderRadius: '7px' }}
            renderValue={(e)=> e}
            value={role} onChange={(e) => {
                setRole(getRoleLabel(e.target.value as unknown as RoleEnum))
                }}>
                {
                    Object.values(RoleEnum).filter((type) => !isNaN(+type)).map((role) => (
                        <MenuItem value={role} key={role}>{getRoleLabel(role as RoleEnum)}</MenuItem>
                    ))
                }
            </Select>
            <IconButton
                sx={{
                    width: '38px', height: '38px', background: '#e4e7eb', borderRadius: '7px',
                    opacity: !role ? .65 : 1, marginLeft: '16px'
                }}
                onClick={() => role && setRole('')}
            >
                <img src={unfilter} alt="" />
            </IconButton>
        </FormControl>
    )
}

export default FilterByRole;