import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import SectionQuestionsItem from "./SectionQuestionsItem";
import { IQuestionsOnSection } from "../../API/interfaces/questions-on-section.interface";
import { IQuestionLookUp } from "../../API/interfaces/questions.interface";
import { FC, useEffect, useState } from "react";
import { QuestionType } from "../../API/enums/questionType.enum";
import FilterByQuestionType from "../Filters/FilterByQuestionType";
import FindByNameTextfield from "../Filters/FindByNameTextfield";

interface IProps {
    sectionQuestions: IQuestionsOnSection[];
    questionsLookUp: IQuestionLookUp[];
    isLeftTable: boolean;
    selectedQuestionId: string;
    setSelectedQuestionId: React.Dispatch<React.SetStateAction<string>>;
    limit: number;
    setLimit: React.Dispatch<React.SetStateAction<number>>
}

const SectionQuestionsList: FC<IProps> = ({ sectionQuestions, questionsLookUp, isLeftTable, selectedQuestionId, setSelectedQuestionId, limit, setLimit }) => {
    const [typeAr, setTypeAr] = useState<string[]>([]);

    const [filteredQuestions, setFilteredQuestions] = useState<IQuestionLookUp[]>([]);
    const [filteredSectionQuestions, setFilteredSectionQuestions] = useState<IQuestionsOnSection[]>([]);
    const [findByName, setFindByName] = useState<string>('');

    const handleFilterByQuestionType = () => {
        typeAr.forEach(choosedType => {
            const choosedType2 = choosedType as unknown as QuestionType
            setFilteredQuestions(questionsLookUp.filter(question =>
                choosedType2 === question.type ? true : false
            ))
        })

        if (!isLeftTable) {
            const array: IQuestionsOnSection[] = [];
            typeAr.forEach(choosedType => {
                const choosedType2 = choosedType as unknown as QuestionType
                sectionQuestions.forEach(sQue => {
                    questionsLookUp.forEach(que => {
                        if (choosedType2 === que.type && que.id === sQue.questionId) {
                            array.push(sQue)
                        }
                    })
                })
            })
            setFilteredSectionQuestions(array)
        }
    }

    const handleFindByName = (searchValue: string) => {
        if (searchValue.length > 0) {
            if (isLeftTable) {
                setFilteredQuestions(questionsLookUp.filter(element => element.name.toLowerCase().includes(searchValue.toLowerCase())))
            }

            if (!isLeftTable) {
                const array: IQuestionsOnSection[] = [];
                typeAr.length > 0 ?
                    filteredSectionQuestions.forEach(sQue => {
                        if (questionsLookUp.find((que) => que.id === sQue.questionId)!.name.toLowerCase().includes(searchValue.toLowerCase())) {
                            array.push(sQue)
                        }
                    })
                    :
                    sectionQuestions.forEach(sQue => {
                        if (questionsLookUp.find((que) => que.id === sQue.questionId)!.name.toLowerCase().includes(searchValue.toLowerCase())) {
                            array.push(sQue)
                        }
                    })
                setFilteredSectionQuestions(array);
            }

            if (typeAr.length > 0) {
                setFilteredQuestions(filteredQuestions.filter(element => element.name.toLowerCase().includes(searchValue.toLowerCase())))
            }
        setFindByName(searchValue)
        }

        if (searchValue.length === 0 && typeAr.length > 0) {
            handleFilterByQuestionType()
        }
    }

    useEffect(handleFilterByQuestionType, [typeAr, isLeftTable, questionsLookUp, sectionQuestions])

    return (
        <TableContainer component={Paper} elevation={0} sx={{ minWidth: '609px', height: '830px', minHeight: 500, display: 'grid', alignItems: 'flex-start', borderRadius: isLeftTable ? '6px 0 0 6px' : '0 6px 6px 0', paddingBottom: '20px' }}>
            <Table style={{ tableLayout: "fixed" }}>
                <TableHead className='tableHead' sx={{ position: 'sticky', top: 0, zIndex: '1000' }}>
                    <TableRow>
                        <TableCell align="center" width='171px'>Тип</TableCell>
                        <TableCell width='365px'>Все вопросы</TableCell>
                        <TableCell width='73px'></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{
                            height: '55px',
                            borderBottom: '1px solid #c8c8c8',
                            position: 'sticky', top: '69px',
                            display: 'block', background: '#f8f9fa',
                            minWidth: '356%', zIndex: '999'
                        }}
                    >
                        {/* isLeftTable && <TextField
                        variant="standard"
                            type="number"
                            value={limit}
                            onChange={(e) => setLimit(Number(e.target.value))}
                            label="Количество вопросов"
                            sx={{margin: '3px 0 0 60px', width: '150px'}}
                    />*/}

                        <TableCell padding="none" sx={{ height: '55px', borderRight: '1px solid #DEE2E6', padding: '0 12px' }}>
                            <FilterByQuestionType
                                typeAr={typeAr} setType={setTypeAr} />
                        </TableCell>
                        <TableCell padding="none" sx={{ borderRight: '1px solid #DEE2E6', padding: '0 12px' }}>
                            <FindByNameTextfield
                                findByName={findByName}
                                setFindByName={setFindByName}
                                width='250px'
                                handleSearch={handleFindByName}
                                />
                        </TableCell>
                        <TableCell padding="none">

                        </TableCell>
                    </TableRow>
                    {isLeftTable ?
                        typeAr.length > 0 || findByName.length > 0 ?
                            filteredQuestions.map((queLookUp) => (
                                <SectionQuestionsItem key={queLookUp.id}
                                    question={queLookUp}
                                    selectedQuestionId={selectedQuestionId}
                                    setSelectedQuestionId={setSelectedQuestionId}
                                />
                            ))
                            :
                            questionsLookUp.map((queLookUp) => (
                                <SectionQuestionsItem key={queLookUp.id}
                                    question={queLookUp}
                                    selectedQuestionId={selectedQuestionId}
                                    setSelectedQuestionId={setSelectedQuestionId}
                                />
                            ))

                        :
                        typeAr.length > 0 || findByName.length > 0 ?
                            filteredSectionQuestions.map((sQue) => (
                                <SectionQuestionsItem key={sQue.id}
                                    question={questionsLookUp.find((que) => que.id === sQue.questionId)!}
                                    selectedQuestionId={selectedQuestionId}
                                    setSelectedQuestionId={setSelectedQuestionId}
                                />
                            ))
                            :
                            sectionQuestions.map((sQue) => (
                                <SectionQuestionsItem key={sQue.id}
                                    question={questionsLookUp.find((que) => que.id === sQue.questionId)!}
                                    selectedQuestionId={selectedQuestionId}
                                    setSelectedQuestionId={setSelectedQuestionId}
                                />
                            ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SectionQuestionsList;