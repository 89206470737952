/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Stack, TableCell, TableRow } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IQuestionLookUp } from '../../API/interfaces/questions.interface';
import { getQuestionTypeLabel } from '../../API/enums/questionType.enum';
import { IChoice } from '../../API/interfaces/choices.interface';
import choicesService from '../../API/services/choices.service';
import glaz from '../../assets/images/glaz.svg';
import showLess from '../../assets/images/showLess.svg';

interface ISectionQuestionsItemProps {
    question?: IQuestionLookUp;
    selectedQuestionId: string;
    setSelectedQuestionId: React.Dispatch<React.SetStateAction<string>>
}

const SectionQuestionsItem: FC<ISectionQuestionsItemProps> = ({ question, selectedQuestionId, setSelectedQuestionId }) => {
    const [isDetailMode, toggleDetailMode] = useState(false);
    const [choices, setChoices] = useState<IChoice[]>([]);

    const getQuestionTypeColor =
        question && question.type === 0 ? '#018DCA' :
            question && question.type === 1 ? '#7a7785' :
                question && question.type === 2 ? '#F46141CC' :
                    question && question.type === 3 ? 'transparent' : '#fff';

    const getQuestionTypeBorderColor =
        question && question.type === 0 ? '#transparent' :
            question && question.type === 1 ? '#transparent' :
                question && question.type === 2 ? '#transparent' :
                    question && question.type === 3 ? '#797979' : '#fff';

    useEffect(() => {
        if (isDetailMode && question && !choices.length) {
            choicesService.search({ questionId: question.id })
                .then((res) => setChoices(res.items))
                .catch((err) => console.log(err));
        }
    }, [isDetailMode])

    const isSelected = question && question.id === selectedQuestionId

    const isSelectedAndDetailModeColor =
        isDetailMode ? '#cde8f4' :
            isSelected ? '#018DCA' : '#fff'

    const fontColor = isDetailMode ? '#000' : isSelected ? '#fff' : '#000'


    const onSelect = () => {
        setSelectedQuestionId(question ? question.id : '')
        if (isDetailMode) {
            toggleDetailMode(false)
        }
    }

    const onDoubleClick = () => setSelectedQuestionId('')

    return (
        <>
            <TableRow
                onDoubleClick={onDoubleClick}
                onClick={onSelect}
                sx={{ backgroundColor: isSelectedAndDetailModeColor }}
            >
                <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '97px', color: fontColor }}>
                    <span
                        style={{
                            width: '16px', height: '16px',
                            borderRadius: '10px', background: getQuestionTypeColor, border: `1px solid ${getQuestionTypeBorderColor}`,
                            display: 'block', margin: '1px 9px 0 0'
                        }}></span>
                    {question && getQuestionTypeLabel(question.type)}
                </TableCell>
                <TableCell align="center" sx={{ color: fontColor }}>
                    {
                        isDetailMode
                            ? (
                                <>
                                    <p>{question?.name}</p>
                                    {
                                        choices.map((ch) => (
                                            <Stack direction='row' flexWrap='nowrap'>
                                                <p key={ch.id} className="ellipsis"
                                                    style={{ color: ch.isCorrect ? '#018DCA' : '#000' }}>
                                                    {ch.text}
                                                </p>
                                            </Stack>))
                                    }
                                </>
                            )
                            : <p className="ellipsis">{question?.name}</p>
                    }
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => toggleDetailMode(mode => !mode)}>
                        <img src={isDetailMode ? showLess : glaz} alt="Больше" />
                    </IconButton>
                </TableCell>
            </TableRow>

        </>
    )
}

export default SectionQuestionsItem;