import { Breadcrumbs, Button, Container, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { IUser } from "../API/interfaces/users.interface";
import userService from "../API/services/users.service";
import { getRoleLabel } from "../API/enums/Role.enum";
import { useAuth } from "../Context/Auth";

const Profile = () => {
    const [profile, setProfile] = useState<IUser>();
    const { signOut } = useAuth();

    useEffect(() => {
        userService.getProfile()
            .then(setProfile)
            .catch(console.log)
    }, [])

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >

            <Breadcrumbs>
                <Typography variant="h1">Главная</Typography>
            </Breadcrumbs>

            {
                profile && (
                    <Stack component={Paper} elevation={6} sx={{ margin: '40px auto 0', width: '1024px', padding: '40px' }}>
                        <Typography color="black" fontSize={24} sx={{ marginTop: '10px' }}>Пользователь: {profile.lastName} {profile.firstName} {profile.middleName}</Typography>
                        <Typography color="black" fontSize={24}>Роль: {getRoleLabel(profile.role)}</Typography>
                        <Button onClick={signOut} variant="contained" sx={{ width: 100, marginTop: '40px', marginLeft: 'auto' }}>Выйти</Button>
                    </Stack>

                )
            }

        </Container>
    )
}

export default Profile;