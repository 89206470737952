export enum QuestionType {
    EASY,
    MADIUM,
    HARD,
    HELP,
}

export function getQuestionTypeLabel(value: QuestionType): string {
    switch(value){
      case QuestionType.EASY: return 'простой';
      case QuestionType.MADIUM: return 'средний';
      case QuestionType.HARD: return 'сложный';
      case QuestionType.HELP: return 'наводящий';
      default: throw new Error('unsupported question type: [' + value + ']');
    }
}