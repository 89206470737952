import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import authService from '../API/services/auth.service';
import { useAuth } from '../Context/Auth';

type stringAndBool = {
    mes: string,
    bool: boolean
}

const RestorePasswordPage = () => {
    const { isAuth } = useAuth();
    const [login, setLogin] = useState('');
    const [message, setMessage] = useState<stringAndBool>({ mes: '', bool: false });
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        authService.restorePasswordRequest({ email: login })
            .then(() => setMessage({ mes: 'Письмо успешно отправлено на почту', bool: true }))
            .catch(() => setMessage({ mes: 'Произошла ошибка', bool: false }))
    }

    if (isAuth) return <Navigate to="/" replace={true} />

    return (
        <Box sx={{ position: 'fixed', backgroundColor: '#c8c8c8', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10000 }}>
            <Box sx={{ width: '420px', height: '450px', marginLeft: 'auto', marginRight: 'auto', marginTop: 'calc(50vh - 210px)', padding: 0, borderRadius: '6px', background: '#fff' }}>
                <form style={{ display: 'grid', rowGap: '30px' }} onSubmit={handleSubmit}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography color='white' textAlign='center' fontWeight='700' fontSize='40px' className='tableHead' height='96px' width='100%' borderRadius='6px 6px 0 0' padding='25px 0'>LMS Quests</Typography>
                    </Stack>
                    <Box sx={{ display: 'grid', rowGap: '4px', width: '140px', maxWidth: '350px', justifySelf: 'center' }}>
                        <TextField label='Почта' required={true} variant='standard' type='text' value={login} sx={{ width: '140px' }}
                            onChange={e => setLogin(e.target.value)} />
                    </Box>
                    <Button size="medium" variant='contained' type="submit" fullWidth
                        sx={{ maxWidth: '158px', justifySelf: 'center' }}
                        disabled={!login}
                    >
                        Отправить пароль
                    </Button>
                    <Button size="medium" variant='outlined' sx={{ height: 45, lineHeight: '20px', width: '158px', justifySelf: 'center' }}
                        onClick={() => navigate('/auth')}
                    >
                        Вернуться
                    </Button>
                    {message.mes && <Typography color={message.bool? '#2f7c31' : 'error'} 
                    textAlign="center" sx={{ margin: '-15px 0' }}>
                        {message.mes}
                    </Typography>}
                </form>
            </Box>
        </Box>
    )
}
export default RestorePasswordPage;