import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import './style/style.css';

import Layout from './components/Layout';
import Users from './components/Users/Users';
import Modules from './components/Modules/Modules';
import Questions from './components/Questions/Questions';
import Answers from './components/Answers/Answers';
import SectionQuestions from './components/QuestionsOnSections/SectionQuestions';
import Settings from './components/Settings/Settings';
import AuthPage from './components/AuthPage';
import RestorePasswordNotification from './components/RestorePasswordNotification';

import Context from './Context';
import PrivateRoutes from './components/PrivateRoutes';
import { RoleEnum } from './API/enums/Role.enum';
import UserPerformance from './components/UserPerformance/UserPerformance';
import HelpRefs from './components/HelpRefs/HelpRefs';

import { createTheme, ThemeProvider } from '@mui/material';
import { ruRU } from '@mui/material/locale';
import Profile from './components/Profile';
import RestorePasswordPage from './components/RestorePasswordPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#018DCA'
    }
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: '40px',
      color: '#1E1E1E',
      marginLeft: '20px'
    },
    h3: {
      fontWeight: 400,
      fontSize: '24px',
      textAlign: 'center',
      padding: '20px 0',
      color: '#fff'
    }
  }
}, ruRU);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Context>
        <BrowserRouter>
          <Routes>
            <Route path='/auth' element={<AuthPage />} />
            <Route path='/restore-password' element={<RestorePasswordPage />} />
            <Route path='/restore-password-complete' element={<RestorePasswordNotification />} />
            <Route element={<Layout />}>
              <Route element={<PrivateRoutes />}>
                <Route path='/' element={<Profile />} />
                <Route path='/user-performance' element={<UserPerformance />} />
              </Route>
              <Route element={<PrivateRoutes requiredRoles={[RoleEnum.ADMIN, RoleEnum.CURATOR]} />}>
                <Route path='/Users' element={<Users />} />
              </Route>
              <Route element={<PrivateRoutes requiredRoles={[RoleEnum.ADMIN]} />}>
                <Route path='/modules' element={<Modules />} />
                <Route path='/modules/:sectionId/questions' element={<SectionQuestions />} />
                <Route path='/questions' element={<Questions />} />
                <Route path='/questions/:questionId/answers' element={<Answers />} />
                <Route path='/settings' element={<Settings />} />
                <Route path='/help' element={<HelpRefs />} />
              </Route>
            </Route>
            <Route path='/*' element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </Context>
    </ThemeProvider>
  );
}

export default App;
