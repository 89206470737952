import { Box, Button, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { ISectionCreate } from '../../API/interfaces/section.interface';

interface IModulesNewProps {
    defaultOrder: number;
    onCreate: (payload: ISectionCreate) => Promise<void>;
}

const ModulesNew: FC<IModulesNewProps> = ({ defaultOrder, onCreate }) => {
    const [name, setName] = useState<string>('');
    const [order, setOrder] = useState<number>(defaultOrder);
    const [successRequirement, setSuccessRequirement] = useState<number>(3);

    const handleCreate = () => {
        onCreate({
            name,
            order,
            successRequirement
        });
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Stack direction="column" flexWrap="nowrap" alignItems='center' gap="10px" sx={{padding: '40px 50px'}}>
                <TextField
                    type="number"
                    variant="standard"
                    sx={{width:'200px'}}
                    required={true}
                    label='Порядковый номер'
                    value={order}
                    onChange={(e) => setOrder(Number(e.target.value))}
                />
                <TextField
                    type="text"
                    variant="standard"
                    sx={{width:'200px'}}
                    required={true}
                    label='Название'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    type="number"
                    variant="standard"
                    sx={{width:'200px'}}
                    required={true}
                    label='Правильных ответов'
                    value={successRequirement}
                    onChange={(e) => setSuccessRequirement(Number(e.target.value))}
                />
            </Stack>
            <Button sx={{ margin: '0 auto 36px' }} 
                variant="contained" 
                size="large" 
                onClick={handleCreate}
                disabled={!(name && order && successRequirement)}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default ModulesNew;