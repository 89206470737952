import { AutocompleteRenderGroupParams } from "@mui/material";
import { darken, lighten, styled } from "@mui/system";

export const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    fontSize: 14,
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
}));

export const GroupItems = styled('ul')({
  padding: 0,
  fontSize: 14,
});

export default function AutocompleteGroupRender(params: AutocompleteRenderGroupParams){
    return (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
    )
}