import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { IconButton, Typography } from '@mui/material';
import popupCross from '../assets/images/cross_popup.svg';

const crossStyle = {
    position: 'relative',
    left: 'calc(100% - 50px)',
    bottom: '50px',
}

interface IPopupProps {
    children: React.ReactElement;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    height?: string;
}

const Popup: React.FC<IPopupProps> = ({ children, open, setOpen, title, height }) => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '538px',
        minHeight: height ? height : '423px',
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        borderRadius: '6px'
    };

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box className='tableHead' borderRadius='6px 6px 0 0'>
                            <Typography variant='h3'>{title}</Typography>
                            <IconButton sx={crossStyle} onClick={handleClose}>
                                <img src={popupCross} alt="X" />
                            </IconButton>
                        </Box>
                        {children}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default Popup;