import { FC, ReactNode } from 'react';
import AuthProvider from './Auth';

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <AuthProvider>
            {children}
        </AuthProvider>
    )
}


export default Context;