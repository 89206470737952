/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Container, Skeleton, TableCell, TableHead, TableRow, Typography, Stack } from '@mui/material';
import sectionQuestionsService from '../../API/services/questions-on-sections.service';
import { IQuestionLookUp } from '../../API/interfaces/questions.interface';
import questionsService from '../../API/services/questions.service';
import sectionService from '../../API/services/section.service';
import { ISection } from '../../API/interfaces/section.interface';
import { IQuestionsOnSection, QuestionsOnSectionsCreateByPack, QuestionsOnSectionsDeleteByPack } from '../../API/interfaces/questions-on-section.interface';
import usePagination from '../../hooks/usePagination';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import cross2 from '../../assets/images/cross2.svg'
import doubleRight from '../../assets/images/doubleRight.svg';
import doubleLeft from '../../assets/images/doubleLeft.svg';
import SectionQuestionsList from './SectionQuestionsList';

const SectionQuestions: FC = () => {
    const { sectionId } = useParams();
    const { limit, offset, setCount } = usePagination();
    const [isLoading, setIsLoading] = useState(true);
    const [limitQuestion, setLimitQuestion] = useState<number>(5);

    const [section, setSection] = useState<ISection>();
    const [sectionQuestions, setSectionQuestions] = useState<IQuestionsOnSection[]>([]);
    const [questionsLookUp, setQuestionsLookUp] = useState<IQuestionLookUp[]>([]);

    const [selectedQuestionId, setSelectedQuestionId] = useState<string>('');

    const searchSectionQuestions = async () => {
        return sectionQuestionsService.search({ sectionId, limit, offset })
            .then((res) => {
                setSectionQuestions(res.items);
                setCount(res.count);
            })
            .catch((err) => console.log(err))
    }

    const addSectionQuestion = async (questionId: string) => {
        if (!sectionId) return;
        return sectionQuestionsService.create({ sectionId, questionId })
            .then(searchSectionQuestions);
    }

    const removeSectionQuestion = async (id: string) => {
        return sectionQuestionsService.delete(id)
            .then(searchSectionQuestions);
    }

    const moveToLeft = () => {
        if (selectedQuestionId !== '') {
            const SQ = sectionQuestions.find(SQ => SQ.questionId === selectedQuestionId);
            if (SQ) {
                removeSectionQuestion(SQ.id)
                setSelectedQuestionId('')
            }
        }
    }

    const moveToRight = () => {
        if (selectedQuestionId !== '') {
            addSectionQuestion(selectedQuestionId)
            setSelectedQuestionId('')
        }
    }

    const filteredQuestionsLookUp = useMemo(() => {
        const sectionQuestionIds = sectionQuestions.map((sQue) => sQue.questionId);
        return questionsLookUp.filter((que) => !sectionQuestionIds.includes(que.id));
    }, [questionsLookUp, sectionQuestions])

    const isInLeft = useMemo(() => {
        return filteredQuestionsLookUp.find(QLUP => QLUP.id === selectedQuestionId) ? true : false
    }, [selectedQuestionId, filteredQuestionsLookUp])

    const isInRight = useMemo(() => {
        return sectionQuestions.map((sQue) => (
            questionsLookUp.find((que) => que.id === sQue.questionId)
        )).find(QLUP => QLUP && QLUP.id === selectedQuestionId) ? true : false
    }, [selectedQuestionId, sectionQuestions])

    const getSelectedIndex = (array: IQuestionLookUp[]) => {
        const selected = array.find(QLUP => QLUP.id === selectedQuestionId) as IQuestionLookUp
        const index = array.indexOf(selected)
        return { selected, index }
    }

    const isOnTop = useMemo(() => {
        if (isInLeft) {
            const data = getSelectedIndex(filteredQuestionsLookUp)
            return data.index === 0 ? true : false
        }
        else if (isInRight) {
            const array: IQuestionLookUp[] = []
            sectionQuestions.forEach(sQue => {
                array.push(questionsLookUp.find((que) => que.id === sQue.questionId)!)
            })
            return array[0].id === selectedQuestionId ? true : false
        }
        else return false
    }, [filteredQuestionsLookUp, questionsLookUp, selectedQuestionId, isInLeft, isInRight])

    const isOnBottom = useMemo(() => {
        if (isInRight) {
            const array: IQuestionLookUp[] = []
            sectionQuestions.forEach(sQue => {
                array.push(questionsLookUp.find((que) => que.id === sQue.questionId)!)
            })
            const data = getSelectedIndex(array)
            return array.at(-1) === data.selected ? true : false
        }
        else if (isInLeft) {
            const data = getSelectedIndex(filteredQuestionsLookUp)
            return filteredQuestionsLookUp.at(-1) === data.selected ? true : false
        }
        else return false
    }, [filteredQuestionsLookUp, questionsLookUp, selectedQuestionId, isInLeft, isInRight])

    const isRightArrayEmpty = useMemo(() => {
        const array: IQuestionLookUp[] = []
        sectionQuestions.forEach(sQue => {
            array.push(questionsLookUp.find((que) => que.id === sQue.questionId)!)
        })
        return array.length > 0 ? false : true
    }, [sectionQuestions, questionsLookUp, filteredQuestionsLookUp])

    const isLeftArrayEmpty = useMemo(() => {
        return filteredQuestionsLookUp.length > 0 ? false : true
    }, [sectionQuestions, questionsLookUp, filteredQuestionsLookUp])

    const selectUp = () => {
        if (!isOnTop && isInLeft) {
            const data = getSelectedIndex(filteredQuestionsLookUp)
            setSelectedQuestionId(filteredQuestionsLookUp[data.index - 1].id)
        }
        else if (!isOnTop && isInRight) {
            const array: IQuestionLookUp[] = []
            sectionQuestions.forEach(sQue => {
                array.push(questionsLookUp.find((que) => que.id === sQue.questionId)!)
            })
            const data = getSelectedIndex(array)
            setSelectedQuestionId(array[data.index - 1].id)
        }
        else return
    }

    const selectDown = () => {
        if (!isOnBottom && isInLeft) {
            const data = getSelectedIndex(filteredQuestionsLookUp)
            setSelectedQuestionId(filteredQuestionsLookUp[data.index + 1].id)
        }
        else if (!isOnBottom && isInRight) {
            const array: IQuestionLookUp[] = []
            sectionQuestions.forEach(sQue => {
                array.push(questionsLookUp.find((que) => que.id === sQue.questionId)!)
            })
            const data = getSelectedIndex(array)
            setSelectedQuestionId(array[data.index + 1].id)
        }
        else return
    }

    const addAllQuestions = () => {
        const array: QuestionsOnSectionsCreateByPack[] = [];
        filteredQuestionsLookUp.forEach(question =>
            array.push({
                sectionId: Number(sectionId),
                questionId: Number(question.id)
            }))
        sectionQuestionsService.createByPack(array)
            .then(searchSectionQuestions)
            .catch(er => console.log(er))
    }

    const deleteAllQuestions = () => {
        const array: QuestionsOnSectionsDeleteByPack[] = [];
        sectionQuestions.forEach(sq => array.push({
            questionsOnSectionsId: Number(sq.id)
        }))
        sectionQuestionsService.deleteByPack(array)
            .then(searchSectionQuestions)
            .catch(er => console.log(er))
    }

    useEffect(() => {
        questionsService.lookUp()
            .then((res) => setQuestionsLookUp(res.items))
            .catch((err) => console.log(err));
    }, [limitQuestion])

    useEffect(() => {
        setIsLoading(true);
        sectionService.searchSection({ id: sectionId })
            .then((res) => setSection(res.items[0]))
            .then(() => searchSectionQuestions())
            .then(() => setIsLoading(false))
            .catch((err) => console.log(err));
    }, [sectionId])

    useEffect(() => {
        if (isLoading) return;
        searchSectionQuestions();
    }, [limit, offset]);

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                setSelectedQuestionId('');
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    if (isLoading) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px', margin: '0 auto' }}>
            <div style={{ marginLeft: '20px' }}>
                <Breadcrumbs sx={{ fontSize: '40px' }}>
                    <Link to="/modules"><Typography fontSize={40} fontWeight='100' color='#1E1E1E'>Модули</Typography></Link>
                    <Typography variant='h1' marginLeft='0'>Вопросы модуля</Typography>
                </Breadcrumbs>
                <Typography fontWeight={300} fontSize='18px' mb='18px'>{section?.name}</Typography>
            </div>

            <Stack flexWrap='nowrap' flexDirection='row'>

                <SectionQuestionsList
                    sectionQuestions={sectionQuestions}
                    questionsLookUp={filteredQuestionsLookUp}
                    isLeftTable={true}
                    selectedQuestionId={selectedQuestionId}
                    setSelectedQuestionId={setSelectedQuestionId}
                    limit={limitQuestion} setLimit={setLimitQuestion}
                />

                <Box sx={{ minWidth: '72px', height: '830px', background: '#fff', paddingBottom: '20px' }}>
                    <TableHead className='tableHead'>
                        <TableRow>
                            <TableCell width='73px'></TableCell>
                        </TableRow>
                    </TableHead>
                    <Box
                        sx={{
                            border: '1px solid #C8C8C8', borderTop: 'none', display: 'flex',
                            flexDirection: 'column', height: '100%', paddingTop: '55px'
                        }}
                    >
                        <button className='arrowButton' onClick={selectUp} disabled={isOnTop || selectedQuestionId === ''}>
                            <ArrowDropUpIcon />
                        </button>
                        <button className='arrowButton' onClick={selectDown} disabled={isOnBottom || selectedQuestionId === ''}>
                            <ArrowDropDownIcon />
                        </button>
                        <button className='arrowButton' onClick={moveToRight} disabled={isInRight || selectedQuestionId === ''}
                            style={{ opacity: isInRight ? '.65' : '1' }}>
                            <ArrowRightIcon />
                        </button>
                        <button className='arrowButton' onClick={moveToLeft} disabled={isInLeft || selectedQuestionId === ''}
                            style={{ opacity: isInLeft ? '.65' : '1' }}>
                            <ArrowLeftIcon />
                        </button>
                        <button className='arrowButton' onClick={addAllQuestions} disabled={isLeftArrayEmpty}
                            style={{ opacity: isLeftArrayEmpty ? '.65' : '1' }}>
                            <img src={doubleRight} alt=">>" />
                        </button>
                        <button className='arrowButton' onClick={deleteAllQuestions} disabled={isRightArrayEmpty}
                            style={{ opacity: isRightArrayEmpty ? '.65' : '1' }}>
                            <img src={doubleLeft} alt="<<" />
                        </button>
                        <button className='arrowButton' onClick={() => setSelectedQuestionId('')}>
                            <img src={cross2} alt="X" />
                        </button>
                    </Box>
                </Box>

                <SectionQuestionsList
                    sectionQuestions={sectionQuestions}
                    questionsLookUp={questionsLookUp}
                    isLeftTable={false}
                    selectedQuestionId={selectedQuestionId}
                    setSelectedQuestionId={setSelectedQuestionId}
                    limit={limitQuestion} setLimit={setLimitQuestion}
                />

            </Stack>

        </Container>
    )
}

export default SectionQuestions;