export enum RoleEnum {
    ADMIN,
    CURATOR,
    EMPLOYEE,
}

export function getRoleLabel(value: RoleEnum): string {
    switch(value){
      case RoleEnum.ADMIN: return 'Администратор';
      case RoleEnum.CURATOR: return 'Куратор';
      case RoleEnum.EMPLOYEE: return 'Сотрудник';
      default: return String(value);
    }
}
