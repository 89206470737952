/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Container, Paper, Table, Skeleton, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { IHelpRef, IHelpRefCreate, IHelpRefUpdate } from '../../API/interfaces/help-refs.interface';
import helpService from '../../API/services/help.service';
import { ISectionLookUp } from '../../API/interfaces/section.interface';
import sectionService from '../../API/services/section.service';
import HelpRefItem from './HelpRefsItem';
import HelpRefsNew from './HelpRefsNew';
import usePagination from '../../hooks/usePagination';
import Popup from '../Popup';
import FindByNameTextfield from '../Filters/FindByNameTextfield';
import FilterByCount from '../Filters/FilterByCount';
import ReverseArrayFilter from '../Filters/ReverseArrayFilter';

const HelpRefs: FC = () => {
    const { limit, setLimit, offset, setCount, Pagination } = usePagination();
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);

    const [helpRefs, setHelpRefs] = useState<IHelpRef[]>([]);
    const [sectionsLookUp, setSectionsLookUp] = useState<ISectionLookUp[]>([]);

    const [filteredHelpRefs, setFilteredHelpRefs] = useState<IHelpRef[]>([]);

    const [findByModuleName, setFindByModuleName] = useState<string>('');
    const [findByHelpRefName, setFindByHelpRefName] = useState<string>('');
    const [isReversed, setReversed] = useState<boolean>(false);

    const searchHelpRefs = async () => {
        return helpService.search({ limit, offset }).then((res) => {
            isReversed && filteredHelpRefs.length === 0 ?
                setHelpRefs(res.items.reverse())
                :
                setHelpRefs(res.items);
            setCount(res.count);
        });
    }

    const createHelpRef = async (payload: IHelpRefCreate) => {
        return helpService.create(payload)
            .then(() => setShowForm(false))
            .then(searchHelpRefs);
    }

    const updateHelpRef = async (id: string, payload: IHelpRefUpdate) => {
        return helpService.update(id, payload).then(searchHelpRefs);
    }

    const deleteHelpRef = async (id: string) => {
        return helpService.delete(id).then(searchHelpRefs);
    }

    const handleFindByModuleName = async (searchValue: string) => {
        if (searchValue.length > 0) {
            if (findByHelpRefName.length > 0) setFindByHelpRefName('');
            setFindByModuleName(searchValue);
            return helpService.search({ moduleName: searchValue })
                .then((res) => {
                    setFilteredHelpRefs(res.items);
                })
                .catch(er => console.log(er))
        }

        if (searchValue.length === 0) {
            searchHelpRefs()
        }
    }

    const handleFindByHelpRefName = async (searchValue: string) => {
        if (searchValue.length > 0) {
            if (findByModuleName.length > 0) setFindByModuleName('');
            setFindByHelpRefName(searchValue);
            return helpService.search({ name: searchValue })
                .then((res) => {
                    setFilteredHelpRefs(res.items);
                })
                .catch(er => console.log(er))
        }

        if (searchValue.length === 0) {
            searchHelpRefs()
        }
    }

    useEffect(() => {
        filteredHelpRefs.length > 0 && filteredHelpRefs.reverse()
    }, [isReversed])

    useEffect(() => {
        filteredHelpRefs.length === 0 && searchHelpRefs().then(() => setIsLoading(false));
    }, [limit, offset, isReversed]);

    useEffect(() => {
        sectionService.lookUp()
            .then((res) => setSectionsLookUp(res.items))
            .catch((err) => console.log(err))
    }, []);

    if (isLoading) return (
        <Container maxWidth={false} sx={{ padding: '44px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '44px 20px' }}>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Breadcrumbs>
                    <Typography variant='h1'>Справки</Typography>
                </Breadcrumbs>

                <Box>
                    <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                        Добавить справку
                    </Button>
                    <Popup open={showForm} setOpen={setShowForm} title='Новая справка'>
                        <HelpRefsNew sections={sectionsLookUp} onCreate={createHelpRef} />
                    </Popup>
                </Box>
            </Box>

            {
                (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, display: 'grid', alignItems: 'flex-start' }}>
                        <Table style={{ tableLayout: "fixed" }}>
                            <TableHead className='tableHead'>
                                <TableRow>
                                    <TableCell width={300}>Модуль</TableCell>
                                    <TableCell width={300}>Заголовок</TableCell>
                                    <TableCell width={240}>Файл PDF</TableCell>
                                    <TableCell width={95}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ background: '#f8f9fa' }}>
                                    <TableCell width={300} sx={{ borderRight: '1px solid #DEE2E6' }}>
                                        <FindByNameTextfield
                                            findByName={findByModuleName}
                                            setFindByName={setFindByModuleName}
                                            width='250px'
                                            handleSearch={handleFindByModuleName}
                                        />
                                    </TableCell>
                                    <TableCell width={300} sx={{ borderRight: '1px solid #DEE2E6' }}>
                                        <FindByNameTextfield
                                            findByName={findByHelpRefName}
                                            setFindByName={setFindByHelpRefName}
                                            width='250px'
                                            handleSearch={handleFindByHelpRefName}
                                        />
                                    </TableCell>
                                    <TableCell width={240}>

                                    </TableCell>
                                    <TableCell sx={{ display: 'flex', gap: '5px' }}>
                                        <FilterByCount count={limit} setCount={setLimit} width='60px'
                                        isDisable={
                                            findByHelpRefName.length > 0 ||
                                            findByModuleName.length > 0
                                        }
                                        />
                                        <ReverseArrayFilter
                                            isReversed={isReversed}
                                            setReversed={setReversed}
                                            refresh={searchHelpRefs} />
                                    </TableCell>
                                </TableRow>
                                {

                                    findByHelpRefName.length > 0 || findByModuleName.length > 0 ?
                                        filteredHelpRefs.map((helpRef) => (
                                            <HelpRefItem key={helpRef.id}
                                                helpRef={helpRef}
                                                sections={sectionsLookUp}
                                                onUpdate={updateHelpRef}
                                                onDelete={deleteHelpRef}
                                            />
                                        ))
                                        :
                                        helpRefs.map((helpRef) => (
                                            <HelpRefItem key={helpRef.id}
                                                helpRef={helpRef}
                                                sections={sectionsLookUp}
                                                onUpdate={updateHelpRef}
                                                onDelete={deleteHelpRef}
                                            />
                                        ))
                                }
                            </TableBody>
                        </Table>
                        <Pagination />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default HelpRefs;
