import { TextField } from "@mui/material";
import { FC } from "react";

interface ICountFilterProps {
    count: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
    width?: string;
    isDisable?: boolean;
}

const FilterByCount: FC<ICountFilterProps> = ({count, setCount, width, isDisable}) => {

    return (
        <TextField
            type="number"
            sx={{ background: '#fff', borderRadius: '7px', width: width, margin: '0 auto' }}
            variant="outlined"
            size='small'
            disabled={isDisable}
            value={count}
            onChange={(e) => {
                Number(e.target.value) > 0 && setCount(Number(e.target.value))
            }}
            
        />
    )
}

export default FilterByCount;