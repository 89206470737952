/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Box, Skeleton, Breadcrumbs, Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ModulesItem from './ModulesItem';
import ModulesNew from './ModulesNew';
import sectionService from '../../API/services/section.service';
import { ISection, ISectionCreate, ISectionUpdate } from '../../API/interfaces/section.interface';
import usePagination from '../../hooks/usePagination';
import Popup from '../Popup';
import FindByNameTextfield from '../Filters/FindByNameTextfield';

const Modules: FC = () => {
    const { limit, offset, setCount, Pagination } = usePagination();
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [sections, setSections] = useState<ISection[]>([]);
    const [findByName, setFindByName] = useState<string>('');

    const searchSections = async () => {
        return sectionService.searchSection({ limit, offset })
            .then((res) => {
                setSections(res.items);
                setCount(res.count);
            });
    }

    const createSection = async (payload: ISectionCreate) => {
        setShowForm(false);
        return sectionService.createSection(payload).then(searchSections);
    }

    const updateSection = async (id: string, payload: ISectionUpdate) => {
        return sectionService.updateSection(id, payload).then(searchSections);
    }

    const deleteSection = async (id: string) => {
        return sectionService.deleteSection(id).then(searchSections);
    }

    const handleFindByName = async (searchValue: string) => {
        if (searchValue.length > 0) {
            return sectionService.searchSection({ name: searchValue })
                .then(res => {
                    setSections(res.items);
                })
                .catch(er => console.log(er))
        }

        if (searchValue.length === 0) {
            searchSections()
        }
    }

    useEffect(() => {
        searchSections().then(() => setIsLoading(false));
    }, [limit, offset])


    if (isLoading) return (
        <Container maxWidth={false} sx={{ padding: '44px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '44px 20px' }} >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Breadcrumbs>
                    <Typography variant='h1'>Модули</Typography>
                </Breadcrumbs>

                <Box>
                    <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                        Добавить
                    </Button>
                    <Popup open={showForm} setOpen={setShowForm} title='Новый модуль'>
                        <ModulesNew defaultOrder={sections.length + 1} onCreate={createSection} />
                    </Popup>
                </Box>
            </Box>

            {
                <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, display: 'grid', alignItems: 'flex-start' }}>
                    <Table style={{ tableLayout: "fixed" }}>
                        <TableHead className='tableHead'>
                            <TableRow>
                                <TableCell width="200">Порядковый номер</TableCell>
                                <TableCell width="400px">Название</TableCell>
                                <TableCell width="310px">Необходимо правильных ответов для перехода к следующему блоку</TableCell>
                                <TableCell width="120">Вопросы</TableCell>
                                <TableCell width="120px"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ height: '55px', background: '#f8f9fa' }}
                            >
                                <TableCell>

                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid #DEE2E6' }}>
                                    <FindByNameTextfield
                                        findByName={findByName}
                                        setFindByName={setFindByName}
                                        width='250px'
                                        handleSearch={handleFindByName}
                                    />
                                </TableCell>
                                <TableCell>

                                </TableCell>
                                <TableCell>

                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                            {
                                sections.map(section =>
                                    <ModulesItem
                                        section={section}
                                        onUpdate={updateSection}
                                        onDelete={deleteSection}
                                        key={section.id}
                                    />)
                            }
                        </TableBody>
                    </Table>
                    <Pagination />
                </TableContainer>

            }
        </Container>
    )
}

export default Modules;