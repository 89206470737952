import apiClient, { ApiClient } from '../apiClient';
import { IQuest, IQuestUpdate} from '../interfaces/quest.interface';

export class QuestService {
    constructor(private api: ApiClient){}

    public async search(){
        return this.api.get<IQuest>('quest');
    }

    public async update(payload: IQuestUpdate){
        return this.api.put<IQuest>('quest', payload);
    }
}
const questService = new QuestService(apiClient)
export default questService;