import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';
import { RoleEnum } from '../API/enums/Role.enum';
import { SignupDto } from '../API/interfaces/auth.interface';
import authService from '../API/services/auth.service';

type Nullable<T> = T | null;

interface IAuthContext {
    isAuth: boolean;
    isAdmin: boolean;
    isCurator: boolean;
    isEmployee: boolean;
    role: Nullable<RoleEnum>;
    signIn: (cred: SignupDto) => Promise<void>;
    signOut: () => void;
}

export const AuthContext = createContext({} as IAuthContext);

export const useAuth = () => useContext(AuthContext);

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuth, setIsAuth] = useState<boolean>(Boolean(localStorage.getItem('access-token')));
    const [role, setRole] = useState<Nullable<RoleEnum>>(Number(localStorage.getItem('access-role')) as Nullable<RoleEnum>);

    const roleValidations = useMemo(() => ({
        isAdmin: role === RoleEnum.ADMIN,
        isCurator: role === RoleEnum.CURATOR,
        isEmployee: role === RoleEnum.EMPLOYEE,
    }), [role]);

    async function signIn(cred: SignupDto) {
        return authService.login(cred)
            .then((auth) => {
                setIsAuth(Boolean(auth.accessToken));
                localStorage.setItem('access-token', auth.accessToken);
                setRole(auth.role);
                localStorage.setItem('access-role', String(auth.role));
            });
    }

    function signOut(){
        setIsAuth(false);
        localStorage.removeItem('access-token');
        setRole(null);
        localStorage.removeItem('access-role');
    }

    return (
        <AuthContext.Provider value={{ isAuth, role, ...roleValidations, signIn, signOut}}>
           { children } 
        </AuthContext.Provider>
    )
}

export default AuthProvider;