/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Container, Paper, Table, TableBody, Skeleton, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import QuestionsNew from './QuestionsNew';
import QuestionsItem from './QuestionsItem';
import questionsService from '../../API/services/questions.service';
import { IQuestion, IQuestionCreate, IQuestionUpdate } from '../../API/interfaces/questions.interface';
import { IHelpRefLookUp } from '../../API/interfaces/help-refs.interface';
import helpService from '../../API/services/help.service';
import usePagination from '../../hooks/usePagination';
import Popup from '../Popup';
import FilterByQuestionType from '../Filters/FilterByQuestionType';
import { QuestionType } from '../../API/enums/questionType.enum';
import FindByNameTextfield from '../Filters/FindByNameTextfield';
import FilterByCount from '../Filters/FilterByCount';
import ReverseArrayFilter from '../Filters/ReverseArrayFilter';

const Questions: FC = () => {
    const { limit, setLimit, offset, setCount, Pagination } = usePagination();
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);

    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [helpRefLookUp, setHelpRefLookUp] = useState<IHelpRefLookUp[]>([]);

    const [typeAr, setTypeAr] = useState<string[]>([]);
    const [filteredQuestions, setFilteredQuestions] = useState<IQuestion[]>([]);
    const [findByName, setFindByName] = useState<string>('');
    const [findByHelpRef, setFindByHelpRef] = useState<string>('');
    const [isReversed, setReversed] = useState<boolean>(false);

    const searchQuestions = async () => {
        return questionsService.search({ limit, offset })
            .then((res) => {
                isReversed ?
                    setQuestions(res.items.reverse())
                    :
                    setQuestions(res.items);
                setCount(res.count);
            })
            .catch((err) => console.log(err))
    }

    const createQuestion = async (payload: IQuestionCreate) => {
        return questionsService.create(payload)
            .then(searchQuestions)
            .then(() => setShowForm(false));
    }

    const updateQuestion = async (id: string, payload: IQuestionUpdate) => {
        return questionsService.update(id, payload)
            .then(searchQuestions);

    }

    const deleteQuestion = async (id: string) => {
        return questionsService.delete(id)
            .then(searchQuestions);
    }

    const handleFilterByType = () => {
        typeAr.forEach(choosedType => {
            const choosedType2 = choosedType as unknown as QuestionType
            setFilteredQuestions(questions.filter(question =>
                choosedType2 === question.type ? true : false
            ))
        })
    }

    const handleFindByName = async (searchValue: string) => {
        if (searchValue.length > 0) {
            if (findByHelpRef.length > 0) setFindByHelpRef('')
            return questionsService.search({ name: searchValue })
                .then((res) => {
                    setFilteredQuestions(res.items);
                    setFindByName(searchValue);
                })
                .catch(er => console.log(er))
        }

        if (typeAr.length > 0) {
            setFilteredQuestions(filteredQuestions.filter(question => question.name.toLowerCase().includes(searchValue.toLowerCase())))
        }

        if (searchValue.length === 0 && typeAr.length > 0) {
            handleFilterByType()
        }


        if (searchValue.length === 0) {
            searchQuestions()
        }
    }

    const handleFindByHelpRef = async (searchValue: string) => {
        if (searchValue.length > 0) {
            if (findByName.length > 0) setFindByName('')
            return questionsService.search({ helpRefName: searchValue })
                .then((res) => {
                    setFilteredQuestions(res.items)
                    setFindByHelpRef(searchValue)
                })
                .catch(er => console.log(er))
        }

        if (typeAr.length > 0) {
            const array: IQuestion[] = [];
            filteredQuestions.filter(question => helpRefLookUp.forEach(helpRef => {
                if (question.helpId === helpRef.id && helpRef.name.toLowerCase().includes(searchValue.toLowerCase())) {
                    array.push(question)
                }
            }))
            setFilteredQuestions(array);
        }

        if (searchValue.length === 0 && typeAr.length > 0) {
            handleFilterByType()
        }

        if (searchValue.length === 0) {
            searchQuestions()
        }
    }

    useEffect(handleFilterByType, [typeAr])

    useEffect(() => {
        filteredQuestions.length > 0 && filteredQuestions.reverse()
    }, [isReversed])

    useEffect(() => {
        helpService.lookUp()
            .then((res) => setHelpRefLookUp(res.items))
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        filteredQuestions.length === 0 && searchQuestions()
            .then(() => setIsLoading(false))
    }, [limit, offset, isReversed]);


    if (isLoading) return (
        <Container maxWidth={false} sx={{ padding: '44px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '44px 20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Breadcrumbs>
                    <Typography variant='h1'>Вопросы</Typography>
                </Breadcrumbs>

                <Box>
                    <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                        Добавить
                    </Button>
                    <Popup open={showForm} setOpen={setShowForm} title='Новый вопрос' height='317px'>
                        <QuestionsNew helpRefs={helpRefLookUp} onCreate={createQuestion} />
                    </Popup>
                </Box>
            </Box>

            {
                <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, display: 'grid', alignItems: 'flex-start' }}>
                    <Table style={{ tableLayout: "fixed" }}>
                        <TableHead className='tableHead'>
                            <TableRow>
                                <TableCell width="80" >Показывать</TableCell>
                                <TableCell width="200">Вопрос</TableCell>
                                <TableCell width="200">Тип</TableCell>
                                <TableCell width="200">Справочный материал</TableCell>
                                <TableCell width="80">Ответы</TableCell>
                                <TableCell width="95px"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ height: '55px', background: '#f8f9fa' }}
                            >
                                <TableCell width="80">
                                </TableCell>
                                <TableCell width="200" align='center' sx={{ borderRight: '1px solid #DEE2E6' }}>
                                    <FindByNameTextfield
                                        findByName={findByName}
                                        setFindByName={setFindByName}
                                        width='250px'
                                        handleSearch={handleFindByName}
                                    />
                                </TableCell>
                                <TableCell width="200" align='center' sx={{ borderRight: '1px solid #DEE2E6' }}>
                                    <FilterByQuestionType typeAr={typeAr} setType={setTypeAr} />
                                </TableCell>
                                <TableCell width="200" align='center' sx={{ borderRight: '1px solid #DEE2E6' }}>
                                    <FindByNameTextfield
                                        findByName={findByHelpRef}
                                        setFindByName={setFindByHelpRef}
                                        width='250px'
                                        handleSearch={handleFindByHelpRef}
                                    />
                                </TableCell>
                                <TableCell width="50" align='center'>

                                </TableCell>
                                <TableCell sx={{ display: 'flex', gap: '5px' }}>
                                    <FilterByCount count={limit} setCount={setLimit} width='60px'
                                    isDisable={
                                        findByName.length > 0 ||
                                        findByHelpRef.length > 0 ||
                                        typeAr.length > 0
                                    }
                                    />
                                    <ReverseArrayFilter
                                        isReversed={isReversed}
                                        setReversed={setReversed}
                                        refresh={searchQuestions} />
                                </TableCell>
                            </TableRow>
                            {
                                typeAr.length > 0 || findByName.length > 0 || findByHelpRef.length > 0 ?
                                    filteredQuestions.map(question => (
                                        <QuestionsItem key={question.id}
                                            question={question}
                                            helpRefs={helpRefLookUp}
                                            onUpdate={updateQuestion}
                                            onDelete={deleteQuestion}
                                        />
                                    ))
                                    :
                                    questions.map(question => (
                                        <QuestionsItem key={question.id}
                                            question={question}
                                            helpRefs={helpRefLookUp}
                                            onUpdate={updateQuestion}
                                            onDelete={deleteQuestion}
                                        />
                                    ))
                            }
                        </TableBody>
                    </Table>
                    <Pagination />
                </TableContainer>

            }
        </Container>
    )
}

export default Questions;