import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { RoleEnum } from '../API/enums/Role.enum';
import { useAuth } from '../Context/Auth'

const PrivateRoutes: FC<{ requiredRoles?: RoleEnum[] }> = ({ requiredRoles }) => {
    const { isAuth, role }  = useAuth();
    const location = useLocation();
    
    if(!isAuth){
        return <Navigate to='/auth' replace={true} state={{ from: location.pathname }} />;
    }

    if(requiredRoles && !requiredRoles.includes(role as RoleEnum)){
        return <Navigate to='/' replace={true} />;
    }

    return <Outlet/>; 
}

export default PrivateRoutes;