import { Breadcrumbs, Stack, Typography, Container, Paper, Button, Skeleton, Box, Checkbox, FormControl, Select, MenuItem, Input } from "@mui/material";
import { useEffect, useState } from "react";
import { QuestTimeLimitType } from "../../API/enums/QuestTimeLimitType.enum";
import questService from "../../API/services/quest.service";
import React from "react";

function getTimeToCompleteLabel(type: QuestTimeLimitType): string {
    switch (type) {
        case QuestTimeLimitType.Quest: return 'Время на прохождение квеста, в секундах';
        case QuestTimeLimitType.Section: return 'Время на прохождение модуля, в секундах';
        case QuestTimeLimitType.Question: return 'Время на прохождение вопроса, в секундах';
        default: return '';
    }
}

const Settings = () => {
    const [loading, setLoading] = useState(true);
    const [retryLimit, setRetryLimit] = useState<number>(5);
    const [helpQuestionsToPass, setHelpQuestionsToPass] = useState<number>(0);
    const [helpQuestionsFailLimit, setHelpQuestionsFailLimit] = useState<number>(0);
    const [incorrectAnswersLimit, setIncorrectAnswersLimit] = useState<number>(0);
    const [timeLimitType, setTimeLimitType] = useState<QuestTimeLimitType>(QuestTimeLimitType.Unlimited);
    const [timeToComplete, setTimeToComplete] = useState<number>(0);
    const [isActive, setActive] = useState<boolean>(true);
    const [isHelpRefAllowed, setHelpRefAllowed] = useState<boolean>(true);
    const [isSimpleMode, setSimpleMode] = useState<boolean>(false);
    const [maxFileSizeMB, setMaxFileSizeMB] = useState<number>(0);

    const [hasChanged, setChanged] = useState<boolean>(false);

    const handleSearch = () => {
        setLoading(true);
        questService.search()
            .then(r => {
                setLoading(false);
                setRetryLimit(r.retryLimit);
                setHelpQuestionsToPass(r.helpQuestionsToPass);
                setHelpQuestionsFailLimit(r.helpQuestionsFailLimit);
                setIncorrectAnswersLimit(r.incorrectAnswersLimit);
                setTimeLimitType(r.timeLimitType)
                setTimeToComplete(r.timeToComplete);
                setActive(r.isActive);
                setHelpRefAllowed(r.isHelpRefAllowed);
                setSimpleMode(r.isSimpleMode);
                setMaxFileSizeMB(r.maxFileSizeMB);
            })
            .catch(er => console.log(er))
    }

    useEffect(handleSearch, [])

    const handleUpdate = () => {
        questService.update({
            retryLimit,
            helpQuestionsToPass,
            helpQuestionsFailLimit,
            incorrectAnswersLimit,
            timeLimitType,
            timeToComplete,
            isActive,
            isHelpRefAllowed,
            isSimpleMode,
            maxFileSizeMB
        }
        ).catch(er => console.log(er))
        setChanged(false);
    }

    useEffect(() => {
        if (!hasChanged) return;



        const handleOnBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = '';
        }
        window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true})
        return () => {
            window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true})
        }
    }, [hasChanged])

    if (loading) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >

            <Breadcrumbs>
                <Typography variant="h1" >Настройки</Typography>
            </Breadcrumbs>

            <Stack component={Paper} elevation={6} sx={{ margin: '40px auto 0', flexDirection: 'column', minWidth: '1281px', minHeight: '830px' }}>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ borderBottom: '1px solid #C8C8C8' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                        <Checkbox sx={{ marginLeft: '68px', marginTop: '10px' }} checked={isActive} onChange={() => { setActive(!isActive); setChanged(true) }} />
                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px' }}>
                        <p style={{ marginLeft: '26px' }}>Активен</p>
                    </Box>
                </Stack>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ borderBottom: '1px solid #C8C8C8' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                        <Input
                            type='number'
                            size='medium'
                            sx={{ width: '86px', marginLeft: '45px', marginTop: '10px' }}
                            value={retryLimit}
                            onChange={(e) => { setRetryLimit(Number(e.target.value)); setChanged(true) }}
                        />

                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px' }}>
                        <p style={{ marginLeft: '26px' }}>Количество попыток прохождения квеста сотрудником</p>
                    </Box>
                </Stack>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ borderBottom: '1px solid #C8C8C8' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                        <Input
                            type='number'
                            size='medium'
                            fullWidth
                            sx={{ width: '86px', marginLeft: '45px', marginTop: '10px' }}
                            value={helpQuestionsToPass}
                            onChange={(e) => { setHelpQuestionsToPass(Number(e.target.value)); setChanged(true) }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px' }}>
                        <p style={{ marginLeft: '26px' }}>Количество наводящих вопросов, на которое нужно ответить успешно для перехода к следующему основному вопросу</p>
                    </Box>
                </Stack>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ borderBottom: '1px solid #C8C8C8' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                        <Input
                            type='number'
                            size='medium'
                            fullWidth
                            sx={{ width: '86px', marginLeft: '45px', marginTop: '10px' }}
                            value={helpQuestionsFailLimit}
                            onChange={(e) => { setHelpQuestionsFailLimit(Number(e.target.value)); setChanged(true) }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px' }}>
                        <p style={{ marginLeft: '26px' }}>Количество наводящих вопросов, на которое необходимо неудачно ответить для окончания прохождения квеста</p>
                    </Box>
                </Stack>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ borderBottom: '1px solid #C8C8C8' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                        <Input
                            type='number'
                            size='medium'
                            fullWidth
                            sx={{ width: '86px', marginLeft: '45px', marginTop: '10px' }}
                            value={incorrectAnswersLimit}
                            onChange={(e) => { setIncorrectAnswersLimit(Number(e.target.value)); setChanged(true) }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px' }}>
                        <p style={{ marginLeft: '26px' }}>Количество допустимых ошибок в квесте</p>
                    </Box>
                </Stack>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ borderBottom: '1px solid #C8C8C8' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                        <FormControl variant='standard' fullWidth sx={{ width: '86px', marginLeft: '45px', marginTop: '10px' }}>
                            <Select value={timeLimitType} onChange={(e) => { setTimeLimitType(e.target.value as QuestTimeLimitType); setChanged(true) }} >
                                {
                                    Object.values(QuestTimeLimitType).map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px' }}>
                        <p style={{ marginLeft: '26px' }}>Ограничение времени</p>
                    </Box>
                </Stack>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ borderBottom: '1px solid #C8C8C8' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                        {
                            timeLimitType !== QuestTimeLimitType.Unlimited && (
                                <Input
                                    type='number'
                                    size='medium'
                                    fullWidth
                                    sx={{ width: '86px', marginLeft: '45px', marginTop: '10px' }}
                                    value={timeToComplete}
                                    onChange={(e) => { setTimeToComplete(Number(e.target.value)); setChanged(true) }}
                                />
                            )
                        }
                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px', height: '51px' }}>
                        <p style={{ marginLeft: '26px' }}>{getTimeToCompleteLabel(timeLimitType)}</p>
                    </Box>
                </Stack>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ borderBottom: '1px solid #C8C8C8' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                        <Input
                            type='number'
                            size='medium'
                            fullWidth
                            sx={{ width: '86px', marginLeft: '45px', marginTop: '10px' }}
                            value={maxFileSizeMB}
                            onChange={(e) => { setMaxFileSizeMB(Number(e.target.value)); setChanged(true) }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px' }}>
                        <p style={{ marginLeft: '26px' }}>Максимальный размер файла справочного материала (МБайт)</p>
                    </Box>
                </Stack>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ borderBottom: '1px solid #C8C8C8' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                        <Checkbox sx={{ marginLeft: '68px' }} checked={isHelpRefAllowed} onChange={() => { setHelpRefAllowed(!isHelpRefAllowed); setChanged(true) }} />
                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px' }}>
                        <p style={{ marginLeft: '26px' }}>Показывать справку во время игры</p>
                    </Box>
                </Stack>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ borderBottom: '1px solid #C8C8C8' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                        <Checkbox sx={{ marginLeft: '68px' }} checked={isSimpleMode} onChange={() => { setSimpleMode(!isSimpleMode); setChanged(true) }} />
                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px' }}>
                        <p style={{ marginLeft: '26px' }}>Отображать в упрощенном виде</p>
                    </Box>
                </Stack>

                <Stack flexWrap='nowrap' flexDirection='row' sx={{ height: '330px' }}>
                    <Box sx={{ width: '178px', background: '#018DCA33' }}>
                    </Box>
                    <Box sx={{ display: 'flex', width: '1103px' }}>
                        <Button
                            variant='contained'
                            size='large'
                            disabled={!hasChanged}
                            onClick={handleUpdate}
                            sx={{ width: '158px', height: '56px', position: 'relative', left: '26px', top: '244px' }}
                        >
                            Сохранить
                        </Button>
                    </Box>
                </Stack>
            </Stack>

        </Container>

    )
}

export default Settings;