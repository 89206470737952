/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { IUserPerformanceDto } from "../../API/interfaces/analytics.interface";
import analyticService from "../../API/services/analytics.service";
import userService from "../../API/services/users.service";
import { useAuth } from "../../Context/Auth";
import usePagination from "../../hooks/usePagination";
import UserPerformanceItem from "./UserPerformanceItem";
import FilterByQuestionType from "../Filters/FilterByQuestionType";
import React from "react";
import FindByNameTextfield from "../Filters/FindByNameTextfield";

const UserPerformance = () => {
    const { isAdmin, isEmployee } = useAuth();
    const [isOpen, toggle] = React.useReducer(v => !v, false);
    const { limit, offset, setCount, Pagination } = usePagination();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [userPerformance, setUserPerformance] = useState<IUserPerformanceDto[]>([]);

    const [typeAr, setTypeAr] = useState<string[]>([]);
    const [filteredUserPerformance, setFilteredUserPerformance] = useState<IUserPerformanceDto[]>([]);
    const [findByName, setFindByName] = useState<string>('');

    const handleFilterByQuestionType = () => {
        const array: IUserPerformanceDto[] = [];
        if (typeAr.length > 0) {
            /* typeAr.forEach(choosedType => {
                 const choosedType2 = choosedType as unknown as QuestionType
                 userPerformance.filter(up => up.sectionsStats?.forEach(section => {
                     section.answers.forEach(answer => {
                         if (choosedType2 === answer.question.type) {
                             array.push(up);
                         }
                     })
                 }))
             }) */
            userPerformance.filter(up => up.sectionsStats?.forEach(section => {
                section.answers.forEach(answer => {
                    if (typeAr.includes(answer.question.type as unknown as string)) {
                        array.push(up)
                    }
                })
            }))
        }
        setFilteredUserPerformance(array);
    }

    const handleNameSearch = (searchValue: string) => {
        if (typeAr.length > 0) {
            setFilteredUserPerformance(filteredUserPerformance
                .filter(FUP => FUP.user && (FUP.user?.firstName + FUP.user?.lastName)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())))
            setFindByName(searchValue)
        }

        if (typeAr.length === 0) {
            setFilteredUserPerformance(userPerformance
                .filter(UP => UP.user && (UP.user.firstName + UP.user.lastName)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())))
            setFindByName(searchValue)
        }

        if (typeAr.length > 0 && searchValue.length === 0) {
            handleFilterByQuestionType()
        }
    }

    useEffect(handleFilterByQuestionType, [typeAr])

    //const [usersLookUp, setUserLookUp] = useState<IUserLookUp[]>([]);
    //const [selectedUser, setSelectedUser] = useState<IUserLookUp | null>(null)
    //const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().subtract(1, 'M'));
    //const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());

    useEffect(() => {
        analyticService.getUserPerformance({
            // ...(selectedUser && { userId: selectedUser.id }),
            // ...(dateFrom?.isValid() && { dateFrom: dateFrom.unix() * 1000 }),
            // ...(dateTo?.isValid() && { dateTo: dateTo.unix() * 1000 }),
            limit,
            offset
        })
            .then(res => {
                setUserPerformance(res.items);
                setCount(res.count);
            })
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [limit, offset,
        //selectedUser, dateFrom, dateTo
    ])

    useEffect(() => {
        if (isEmployee) return;
        userService.lookUp()
            .then(res => res.items)
            //.then(setUserLookUp)
            .catch(console.log);
    }, [])

    if (loading) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )

    if (error) {
        return <h2 style={{ margin: '20% auto', width: 'fit-content' }}>Произошла ошибка</h2>;
    }

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Breadcrumbs>
                <Typography variant='h1'>Аналитика</Typography>
            </Breadcrumbs>
            {/*<Box sx={{ marginTop: '40px' }}>
                <Stack direction="row" flexWrap="wrap" gap="10px">
                    {
                        !isEmployee && (
                            <Autocomplete
                                disablePortal
                                options={usersLookUp.sort((a, b) => String(b.role).localeCompare(String(a.role)))}
                                groupBy={(option) => (option.role !== null) ? getRoleLabel(option.role) : 'Без роли'}
                                getOptionLabel={(option) => option.fullName}
                                renderGroup={AutocompleteGroupRender}
                                onChange={(e, value) => { setSelectedUser(value); }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Сотрудник" />}
                            />
                        )
                    }
                    <LocalizationProvider adapterLocale="ru-RU" dateAdapter={AdapterDayjs} localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText} >
                        <DateField label="Начиная с" value={dateFrom} onChange={(value) => (!value || value.isValid()) && setDateFrom(value)} maxDate={dateTo} format="DD/MM/YYYY" />
                        <DateField label="Заканчивая по" value={dateTo} onChange={(value) => (!value || value.isValid()) && setDateTo(value)} minDate={dateFrom} format="DD/MM/YYYY" />
                    </LocalizationProvider>
                </Stack>
                </Box>*/}

            <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, display: 'grid', alignItems: 'flex-start' }}>
                <Table>
                    <TableHead className='tableHead'>
                        <TableRow>
                            <TableCell align='center'>Квест</TableCell>
                            {!isEmployee && <TableCell align="center">Сотрудник</TableCell>}
                            {isAdmin && <TableCell align="center">Куратор</TableCell>}
                            <TableCell align="center">Модуль и попытки</TableCell>
                            <TableCell align="center">Дата</TableCell>
                            <TableCell align="center">Ответы</TableCell>
                            <TableCell width="60px"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isOpen && <TableRow
                            sx={{ height: '55px', borderBottom: '1px solid #c8c8c8', background: '#f8f9fa' }}
                        >
                            <TableCell align='center' sx={{ borderRight: '1px solid #DEE2E6' }}>
                                <FilterByQuestionType typeAr={typeAr} setType={setTypeAr} isAnalytics={true} />
                            </TableCell>
                            {!isEmployee && <TableCell align="center" sx={{ borderRight: '1px solid #DEE2E6' }}>
                                <FindByNameTextfield
                                    findByName={findByName}
                                    setFindByName={setFindByName}
                                    width='200px'
                                    handleSearch={handleNameSearch}
                                />
                            </TableCell>}
                            {isAdmin && <TableCell align="center" sx={{ borderRight: '1px solid #DEE2E6' }}>

                            </TableCell>}
                            <TableCell align="center" sx={{ borderRight: '1px solid #DEE2E6' }}>

                            </TableCell>
                            <TableCell align="center" sx={{ borderRight: '1px solid #DEE2E6' }}>

                            </TableCell>
                            <TableCell align="center" sx={{ borderRight: '1px solid #DEE2E6' }}>

                            </TableCell>
                            <TableCell width="60px"></TableCell>
                        </TableRow>}

                        { //<UserPerformanceItem isAdmin={isAdmin} isEmployee={isEmployee} userPerformance={mockData} />
                        }


                        {typeAr.length > 0 || findByName.length > 0 ?
                            filteredUserPerformance.map((userPerformance) => (
                                <UserPerformanceItem key={userPerformance.id}
                                    userPerformance={userPerformance}
                                    isAdmin={isAdmin}
                                    isEmployee={isEmployee}
                                    toggle={toggle}
                                />
                            ))
                            :
                            userPerformance.map((userPerformance) => (
                                <UserPerformanceItem key={userPerformance.id}
                                    userPerformance={userPerformance}
                                    isAdmin={isAdmin}
                                    isEmployee={isEmployee}
                                    toggle={toggle}
                                />
                            ))
                        }
                    </TableBody>
                </Table>
                <Pagination />
            </TableContainer>
        </Container>

    )
}

export default UserPerformance;