import { Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { ISectionLookUp } from '../../API/interfaces/section.interface';
import { IHelpRefCreate } from '../../API/interfaces/help-refs.interface';

interface IHelpRefsNewProps {
    onCreate: (payload: IHelpRefCreate) => Promise<void>;
    sections: ISectionLookUp[];
}

const HelpRefsNew: FC<IHelpRefsNewProps> = ({ sections, onCreate }) => {
    const [name, setName] = useState('');
    const [section, setSection] = useState<ISectionLookUp | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const [error, setError] = useState('');

    const handleCreate = () => {
        onCreate({ name, sectionId: section?.id, ...file && { file } })
            .catch((err) => {
               console.log(err);
               setError("Файл слишком большой либо уже был загружен");
            });
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Stack direction="column" flexWrap="nowrap" alignItems='center' gap="20px" padding='40px 50px'>
                <Autocomplete
                    disablePortal
                    options={sections}
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => { setSection(value) }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Модуль" />}
                />
                <TextField sx={{ width: '300px' }}
                    label='Заголовок'
                    type="text"
                    variant="standard"
                    multiline
                    maxRows={3}
                    minRows={1}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <Button component="label" variant="outlined" size="large" sx={{ width: '300px' }} color={Boolean(error) ? 'error' : 'primary'}>
                    <span className="ellipsis">{ file ? file.name : "Загрузить PDF" }</span>
                    <input type="file" onChange={(e) => {
                        if(e.target.files){
                            setFile(e.target.files[0]);
                            setError('');
                        }
                    }} accept="application/pdf" hidden />
                </Button>
            {
                error && <Typography textAlign='center' m='0' color="error">{error}</Typography>
            }
            </Stack>
            <Button sx={{ margin: '0 auto 36px' }}
                variant="outlined"
                size="large"
                onClick={handleCreate}
                disabled={!(name && section && file)}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default HelpRefsNew;