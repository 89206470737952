import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/common.interface';
import { IQuestion, IQuestionCreate, IQuestionLookUp, IQuestionSearch, IQuestionUpdate} from '../interfaces/questions.interface';

export class QuestionsService {
    constructor(private api: ApiClient){}

    public async lookUp(){
        return this.api.get<SearchResult<IQuestionLookUp>>('questions/lookup');
    }

    public async search(query?: IQuestionSearch){
        return this.api.get<SearchResult<IQuestion>>('questions', query);
    }

    public async create(payload: IQuestionCreate){
        return this.api.post<IQuestion>('questions', payload);
    }

    public async update(id: string, payload: IQuestionUpdate){
        return this.api.put<IQuestion>('questions/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('questions/' + id);
    }
}
const questionsService = new QuestionsService(apiClient)
export default questionsService;