/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { getRoleLabel, RoleEnum } from '../../API/enums/Role.enum';
import { IUserCreate, IUserLookUp } from '../../API/interfaces/users.interface';

interface IUserNewProps {
    onCreate: (payload: IUserCreate) => void;
    curators: IUserLookUp[];
}

const UsersNew: FC<IUserNewProps> = ({ onCreate, curators }) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [role, setRole] = useState(RoleEnum.EMPLOYEE);
    const [selectedCurator, setSelectedCurator] = useState<IUserLookUp | null>(null);

    const handleCreateUser = () => {
        onCreate({
            isActive: true,
            login,
            password,
            password2,
            firstName,
            middleName,
            lastName,
            role,
            curatorId: selectedCurator?.id || null
        })
    }

    useEffect(() => {
        if (role === RoleEnum.EMPLOYEE && !selectedCurator && curators[0]) {
            return setSelectedCurator(curators[0]);
        }

        if (role !== RoleEnum.EMPLOYEE && selectedCurator) {
            return setSelectedCurator(null);
        }
    }, [curators, selectedCurator, role])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Stack direction="column" flexWrap="wrap" gap="10px" style={{ position: "relative", padding: '40px 50px' }}>
                <Stack direction='row' flexWrap='nowrap' gap='10px' mb='10px'>
                    <TextField
                        type="text"
                        variant="standard"
                        sx={{ width: '160px' }}
                        required={true}
                        label='Email'
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                    />
                    <TextField
                        type="text"
                        variant="standard"
                        sx={{ width: '160px' }}
                        label='Пароль'
                        required={true}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        type="text"
                        variant="standard"
                        sx={{ width: '160px', fontSize: '14px' }}
                        label='Повторите пароль'
                        required={true}
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        error={password !== password2}
                    />
                </Stack>
                <Stack direction='row' flexWrap='wrap' gap='10px' mb='10px'>
                    <TextField
                        type="text"
                        variant="standard"
                        sx={{ width: '160px' }}
                        required={true}
                        label='Фамилия'
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <TextField
                        type="text"
                        variant="standard"
                        sx={{ width: '160px' }}
                        required={true}
                        label='Имя'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                        type="text"
                        variant="standard"
                        sx={{ width: '160px' }}
                        label='Отчество'
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                    />
                </Stack>
                <Stack direction='row' flexWrap='wrap' gap='10px' mb={role === RoleEnum.EMPLOYEE ? '10px' : 0}>
                    <FormControl variant='standard' sx={{ width: '500px', paddingTop: '5px' }}>
                        <InputLabel>Роль</InputLabel>
                        <Select value={role} onChange={(e) => setRole(e.target.value as RoleEnum)}>
                            {
                                Object.values(RoleEnum).filter((type) => !isNaN(+type)).map((role) => (
                                    <MenuItem value={role} key={role}>{getRoleLabel(role as RoleEnum)}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction='row' flexWrap='wrap' gap='10px'>
                    {
                        role === RoleEnum.EMPLOYEE && (
                            <Autocomplete
                                disablePortal
                                options={curators}
                                getOptionLabel={(option) => option.fullName}
                                onChange={(e, value) => { setSelectedCurator(value); }}
                                sx={{ width: 500 }}
                                renderInput={(params) => <TextField {...params} label="Куратор" sx={{ height: '43px' }} />}
                            />
                        )
                    }
                </Stack>
            </Stack>

            <Button sx={{ margin: '0 auto 36px' }}
                variant="contained"
                size="large"
                onClick={handleCreateUser}
                disabled={!(firstName && lastName && login && password && password2 && (password === password2))}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default UsersNew;