export function getTimeString(time: number){
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.ceil(time % 60);
    let string = '';
    if(hours) {
        string += hours < 10 ? '0' + hours : hours;
        string += ':';
    }
    string += minutes < 10 ? '0' + minutes : minutes;
    string += ':';
    string += seconds < 10 ? '0' + seconds : seconds;
    return string;
}