import apiClient, { ApiClient } from '../apiClient';
import { IUserPerformanceResponseDto, IUserPerformanceSearch } from '../interfaces/analytics.interface';

export class AnalyticsService {
    constructor(private api: ApiClient){}

    public async getUserPerformance(search?: IUserPerformanceSearch){
        return this.api.get<IUserPerformanceResponseDto>('analytics/user-performance', search)
    }
}

const analyticService = new AnalyticsService(apiClient);
export default analyticService;