import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/common.interface';
import { IQuestionsOnSection, IQuestionsOnSectionCreate, IQuestionsOnSectionSearch, IQuestionsOnSectionUpdate, QuestionsOnSectionsCreateByPack, QuestionsOnSectionsDeleteByPack} from '../interfaces/questions-on-section.interface';

export class QuestionsOnSections {
    constructor(private api: ApiClient){}

    public async search(query?: IQuestionsOnSectionSearch){
        return this.api.get<SearchResult<IQuestionsOnSection>>('questions-on-sections', query);
    }

    public async create(payload: IQuestionsOnSectionCreate){
        return this.api.post<IQuestionsOnSection>('questions-on-sections', payload);
    }

    public async update(id: string, payload: IQuestionsOnSectionUpdate){
        return this.api.put<IQuestionsOnSection>('questions-on-sections/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('questions-on-sections/' + id);
    }

    public async createByPack(payload: QuestionsOnSectionsCreateByPack[]){
        return this.api.post<void>('questions-on-sections/batch', payload);
    }

    public async deleteByPack(payload: QuestionsOnSectionsDeleteByPack[]){
        return this.api.delete<void>('questions-on-sections/batch', payload);
    }
}

const questionsOnSectionsService = new QuestionsOnSections(apiClient);

export default questionsOnSectionsService;