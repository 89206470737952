import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/common.interface';
import { ISection, ISectionCreate, ISectionLookUp, ISectionSearch, ISectionUpdate} from '../interfaces/section.interface';

export class SectionService {
    constructor(private api: ApiClient){}

    public async lookUp(){
        return this.api.get<SearchResult<ISectionLookUp>>('sections/lookup');
    }

    public async searchSection(query?: ISectionSearch){
        return this.api.get<SearchResult<ISection>>('sections', query);
    }

    public async createSection(payload: ISectionCreate){
        return this.api.post<ISection>('sections', payload);
    }

    public async updateSection(id: string, payload: ISectionUpdate){
        return this.api.put<ISection>('sections/' + id, payload);
    }

    public async deleteSection(id: string){
        return this.api.delete<void>('sections/' + id);
    }
}

const sectionService = new SectionService(apiClient);

export default sectionService;