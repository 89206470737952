import { Box, Button, Stack, TextField, Typography, Checkbox } from '@mui/material';
import { FC, useState } from 'react';
import { IChoiceCreate } from '../../API/interfaces/choices.interface';

interface IProps {
    questionId: string;
    onCreate: (payload: IChoiceCreate) => void;
}

const AnswersNew: FC<IProps> = ({ questionId, onCreate }) => {
    const [text, setText] = useState<string>('');
    const [isCorrect, setCorrect] = useState<boolean>(false);

    const handleCreate = () => onCreate({ questionId, text, isCorrect })

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' ,alignItems: 'center' }}>
            <Box padding='40px 50px'>
                <TextField sx={{ width: '300px' }}
                    type="text"
                    variant="standard"
                    label='Текст ответа'
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    required={true}
                    multiline={true}
                    minRows={1}
                    maxRows={5}
                />
                <Stack direction='row' width='fit-content' alignItems='center' marginTop={'20px'}>
                    <Checkbox checked={isCorrect} onChange={() => setCorrect(!isCorrect)} sx={{ width: '28px', height: '28px' }} />
                    <Typography>Правильный ответ</Typography>
                </Stack>
            </Box>
            <Button sx={{ margin: '0 auto 36px' }}
                variant="contained"
                size="large"
                onClick={handleCreate}
                disabled={!text}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default AnswersNew;