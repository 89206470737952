import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/common.interface';
import { IUser, IUserCreate, IUserLookUp, IUserSearch, IUserUpdate} from '../interfaces/users.interface';

export class UsersService {
    constructor(private api: ApiClient){}

    public async getProfile(){
        return this.api.get<IUser>('users/profile');
    }

    public async lookUp(){
        return this.api.get<SearchResult<IUserLookUp>>('users/lookup');
    }

    public async searchUsers(query?: IUserSearch){
        return this.api.get<SearchResult<IUser>>('users', query);
    }

    public async createUser(payload: IUserCreate){
        return this.api.post<IUser>('users', payload);
    }

    public async updateUser(id: string, payload: IUserUpdate){
        return this.api.put<IUser>('users/' + id, payload);
    }

    public async deleteUser(id: string){
        return this.api.delete<void>('users/' + id);
    }
}

const userService = new UsersService(apiClient);

export default userService;