import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Skeleton, Breadcrumbs, Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AnswersNew from './AnswersNew';
import AnswersItem from './AnswersItem';
import questionsService from '../../API/services/questions.service';
import { IQuestion } from '../../API/interfaces/questions.interface';
import choicesService from '../../API/services/choices.service';
import { IChoice, IChoiceCreate } from '../../API/interfaces/choices.interface';
import Popup from '../Popup';

const Answers: FC = () => {
    const { questionId } = useParams();
    const [question, setQuestion] = useState<IQuestion>();
    const [choices, setChoices] = useState<IChoice[]>([]);
    const [showForm, setShowForm] = useState(false);

    const searchQuestion = () => {
        questionsService.search({ id: questionId })
            .then(r => setQuestion(r.items[0]))
            .catch(er => console.log(er));
    }

    const searchAnswers = () => {
        choicesService.search({ questionId })
            .then(r => setChoices(r.items))
            .catch(er => console.log(er));
    }

    const createAnswer = (payload: IChoiceCreate) => {
        choicesService.create(payload)
            .then(searchAnswers)
            .then(() => setShowForm(false));
    }

    const updateAnswer = (payload: IChoice) => {
        choicesService.update(payload.id, payload)
            .then(searchAnswers);
    }

    const deleteAnswer = (payload: IChoice) => {
        choicesService.delete(payload.id)
            .then(searchAnswers);
    }


    useEffect(searchQuestion, [questionId]);
    useEffect(searchAnswers, [questionId]);

    if (!questionId) return null;

    if (!question || !choices) return (
        <Container maxWidth={false} sx={{ padding: '44px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '44px 20px' }} >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{marginLeft: '20px'}}>
                    <Breadcrumbs sx={{ fontSize: '40px' }} >
                        <Link to="/questions"><Typography fontSize={40} fontWeight='100' color='#1E1E1E'>Вопросы</Typography></Link>
                        <Typography variant='h1' marginLeft='0'>Ответы на вопрос</Typography>
                    </Breadcrumbs>
                    <Typography fontWeight={300} fontSize='18px' mb='18px'>{question?.name}</Typography>
                </div>

                <Box sx={{ marginTop: '40px' }}>
                    <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                        Добавить
                    </Button>
                    <Popup open={showForm} setOpen={setShowForm} title='Новый ответ' height='317px'>
                        <AnswersNew questionId={questionId} onCreate={createAnswer} />
                    </Popup>
                </Box>
            </Box>
            {
                <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500 }}>
                    <Table>
                        <TableHead sx={{ background: '#018DCA', height: '74px' }}>
                            <TableRow>
                                <TableCell align="left" width="120px" sx={{ color: '#fff', fontWeight: 400 }}>Правильный</TableCell>
                                <TableCell align="left" sx={{ color: '#fff', fontWeight: 400 }}>Ответ</TableCell>
                                <TableCell align="center" width="120px"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                choices.map(choice => (
                                    <AnswersItem choice={choice} onUpdate={updateAnswer} onDelete={deleteAnswer} key={choice.id} />
                                ))

                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            }
        </Container>
    )
}

export default Answers;