/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react';
import { Box, Skeleton, Breadcrumbs, Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import UsersNew from './UsersNew';
import { IUser, IUserCreate, IUserLookUp, IUserUpdate } from '../../API/interfaces/users.interface';
import UsersItem from './UsersItem';
import usersService from '../../API/services/users.service';
import { RoleEnum, getRoleLabel } from '../../API/enums/Role.enum';
import usePagination from '../../hooks/usePagination';
import userService from '../../API/services/users.service';
import Popup from '../Popup';
import FilterByCount from '../Filters/FilterByCount';
import ReverseArrayFilter from '../Filters/ReverseArrayFilter';
import FindByNameTextfield from '../Filters/FindByNameTextfield';
import FilterByRole from '../Filters/FilterByRole';
import { useAuth } from '../../Context/Auth';

const Users: FC = () => {
    const { limit, offset, setLimit, setCount, Pagination } = usePagination();
    const { isAdmin } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);

    const [users, setUsers] = useState<IUser[]>([]);
    const [usersLookUp, setUsersLookUp] = useState<IUserLookUp[]>([]);
    const [isReversed, setReversed] = useState<boolean>(false);

    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);

    const [findByFullName, setFindByFullName] = useState<string>('');
    const [findByEmail, setFindByEmail] = useState<string>('');

    const [role, SetRole] = useState<string>('')

    const searchUsers = async () => {
        return usersService.searchUsers({ limit, offset })
            .then((res) => {
                isReversed ?
                    setUsers(res.items.reverse())
                    :
                    setUsers(res.items);
                setCount(res.count);
            })
            .catch((err) => console.log(err));
    }

    const createUser = async (payload: IUserCreate) => {
        return usersService.createUser(payload)
            .then(() => setShowForm(false))
            .then(searchUsers)
            .catch((err) => console.log(err));
    }

    const updateUser = async (userId: string, payload: IUserUpdate) => {
        return usersService.updateUser(userId, payload)
            .then(searchUsers)
            .catch((err) => console.log(err));
    }

    const deleteUser = async (userId: string) => {
        return usersService.deleteUser(userId)
            .then(searchUsers)
            .catch((err) => console.log(err));
    }

    const handleEmailSearch = async (searchValue: string) => {
        if (searchValue.length > 0) {
            if (findByFullName.length > 0) setFindByFullName('');
            setFindByEmail(searchValue)
            return userService.searchUsers({ login: searchValue })
                .then(res => setFilteredUsers(res.items))
                .catch(er => console.log(er))
        }
    }

    const handleFullNameSearch = async (searchValue: string) => {
        if (searchValue.length > 0) {
            if (findByEmail.length > 0) setFindByEmail('');
            setFindByFullName(searchValue)
            return userService.searchUsers({ fullName: searchValue })
                .then(res => setFilteredUsers(res.items))
                .catch(er => console.log(er))
        }

        if (role.length > 0 && searchValue.length === 0) {
            handleRoleFilter()
        }
    }

    const handleRoleFilter = () => {
        if (role.length > 0) {
            findByEmail.length > 0 || findByFullName.length > 0 ?
                setFilteredUsers(filteredUsers.filter(user => getRoleLabel(user.role) === role))
                :
                setFilteredUsers(users.filter(user => getRoleLabel(user.role) === role))
        }
    }

    useEffect(handleRoleFilter, [role])

    useEffect(() => {
        userService.lookUp()
            .then((res) => setUsersLookUp(res.items))
            .catch((err) => console.log(err));
    }, [])

    useEffect(() => {
        searchUsers().then(() => setIsLoading(false))
    }, [limit, offset, isReversed])

    if (isLoading) return (
        <Container maxWidth={false} sx={{ padding: '44px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )

    const curators = usersLookUp.filter((user) => user.role === RoleEnum.CURATOR);

    return (
        <Container maxWidth={false} sx={{ padding: '44px 20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Breadcrumbs>
                    <Typography variant='h1'>Пользователи</Typography>
                </Breadcrumbs>

                <Box>
                    <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                        Добавить
                    </Button>
                    <Popup open={showForm} setOpen={setShowForm} title='Новый сотрудник'>
                        <UsersNew onCreate={createUser} curators={curators} />
                    </Popup>
                </Box>
            </Box>

            {
                (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, display: 'grid', alignItems: 'flex-start' }}>
                        <Table>
                            <TableHead className='tableHead'>
                                <TableRow>
                                    <TableCell sx={{ width: 100 }}>Активен</TableCell>
                                    <TableCell sx={{ width: 200 }}>Email</TableCell>
                                    <TableCell sx={{ width: 200 }}>Фамилия</TableCell>
                                    <TableCell sx={{ width: 200 }}>Имя</TableCell>
                                    <TableCell sx={{ width: 200 }}>Отчество</TableCell>
                                    <TableCell sx={{ width: 200 }}>Роль</TableCell>
                                    <TableCell sx={{ width: 200 }}>Куратор</TableCell>
                                    <TableCell width="120px"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ background: '#f8f9fa' }}>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center' sx={{ borderRight: '1px solid #C8C8C8' }}>
                                        <FindByNameTextfield
                                            findByName={findByEmail}
                                            setFindByName={setFindByEmail}
                                            width='150px'
                                            handleSearch={handleEmailSearch}
                                        />
                                    </TableCell>
                                    <TableCell>

                                    </TableCell>
                                    <TableCell>
                                        <FindByNameTextfield
                                            findByName={findByFullName}
                                            setFindByName={setFindByFullName}
                                            width='200px'
                                            handleSearch={handleFullNameSearch}
                                        />

                                    </TableCell>
                                    <TableCell align='center' sx={{ borderRight: '1px solid #C8C8C8' }}>

                                    </TableCell>
                                    <TableCell align='center' sx={{ borderRight: '1px solid #C8C8C8' }}>
                                        <FilterByRole role={role} setRole={SetRole} />
                                    </TableCell>
                                    <TableCell align='center' ></TableCell>
                                    <TableCell sx={{ display: 'flex', gap: '5px' }}>
                                        <FilterByCount count={limit} setCount={setLimit} width='60px'
                                            isDisable={
                                                findByEmail.length > 0 ||
                                                findByFullName.length > 0 ||
                                                role.length > 0
                                            }
                                        />
                                        <ReverseArrayFilter
                                            isReversed={isReversed}
                                            setReversed={setReversed}
                                            refresh={searchUsers}
                                            isDisable={
                                                findByEmail.length > 0 ||
                                                findByFullName.length > 0 ||
                                                role.length > 0
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                {
                                    findByEmail.length > 0 ||
                                        findByFullName.length > 0
                                        || role ?
                                        filteredUsers.map((user) => (
                                            <UsersItem user={user} curators={curators} onUpdate={updateUser} onDelete={deleteUser} key={user.id} isAdmin={isAdmin} />
                                        ))
                                        :
                                        users.map((user) => (
                                            <UsersItem user={user} curators={curators} onUpdate={updateUser} onDelete={deleteUser} key={user.id} isAdmin={isAdmin} />
                                        ))
                                }
                            </TableBody>
                        </Table>
                        <Pagination />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default Users;