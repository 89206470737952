import { Box, Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import apiClient from '../API/apiClient';

const RestorePasswordNotification = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    
    const [isLoading, setLoading] = useState(true);
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetch(apiClient.getApiUrl('auth/restore-password-complete'), 
        { 
            method: 'POST', 
            headers: { authorization: `Bearer ${token}`}
        })
            .then((res) => { if (!res.ok) throw Error(res.statusText) })
            .then(() => setMessage('Новый пароль успешно отправлен на почту'))
            .catch(() => setMessage('Произошла ошибка, попробуйте еще раз'))
            .finally(() => setLoading(false));
    }, [token])

    if(!token) return <Navigate to="/" replace={true} />

    if(isLoading) return (
        <Box sx={{ position: 'fixed', backgroundColor: '#fff', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10000 }}>
            <CircularProgress size='86px' sx={{ display: 'flex', margin: '18% 45% 0' }} />
        </Box>
    )

    return (
        <Container sx={{ margin: '20% auto 0', width: '100%' }}>
            <p style={{ width: '100%', textAlign: 'center', fontSize: 26 }}>{message}</p>
        </Container>
    )
}

export default RestorePasswordNotification;