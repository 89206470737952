import { FC } from "react";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ICountFilteringProps {
    isReversed: boolean;
    setReversed: React.Dispatch<React.SetStateAction<boolean>>;
    refresh: () => Promise<void>;
    isDisable?: boolean;
}

const ReverseArrayFilter: FC<ICountFilteringProps> = ({ isReversed, isDisable, setReversed, refresh }) => {

    const handleClick = () => {
        setReversed(!isReversed);
        refresh();
    }

    return (
        <button className='arrowButton' onClick={handleClick}
            disabled={isDisable}
            style={{ opacity: isDisable ? .65 : 1 }}
        >
            {
                isReversed ?
                    <ArrowDropUpIcon />
                    :
                    <ArrowDropDownIcon />
            }
        </button>
    )
}

export default ReverseArrayFilter;