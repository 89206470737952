
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { getQuestionTypeLabel, QuestionType } from '../../API/enums/questionType.enum';
import { IHelpRefLookUp } from '../../API/interfaces/help-refs.interface';
import { IQuestionCreate } from '../../API/interfaces/questions.interface';
import AutocompleteGroupRender from '../AutocompleteGroupRender';


interface IQuestionNewProps {
    helpRefs: IHelpRefLookUp[];
    onCreate: (payload: IQuestionCreate) => void;
}

const QuestionsNew: FC<IQuestionNewProps> = ({ onCreate, helpRefs }) => {
    const [type, setType] = useState(QuestionType.EASY);
    const [name, setName] = useState('');
    const [helpRef, setHelpRef] = useState<IHelpRefLookUp | null>(null);

    const handleCreate = () => onCreate({ type, name, helpId: helpRef?.id });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box padding='40px 50px'>
                <Stack direction="row" flexWrap="nowrap" gap="10px" marginBottom='11px' >
                    <TextField
                        type="text"
                        variant="standard"
                        sx={{ width: '200px' }}
                        required={true}
                        label='Текст вопроса'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Stack>
                <Stack direction="row" flexWrap="nowrap" gap="10px">
                    <FormControl variant='standard' sx={{ width: '220px', paddingTop:'5px' }}>
                        <InputLabel>Тип вопроса</InputLabel>
                        <Select value={type} onChange={(e) => setType(Number(e.target.value))} >
                            {
                                Object.values(QuestionType).filter((type) => !isNaN(+type))
                                    .map((type) => <MenuItem value={type} key={type}>{getQuestionTypeLabel(type as QuestionType)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <Autocomplete sx={{ width: 200 }}
                        disablePortal
                        options={helpRefs}
                        groupBy={(option) => option.section}
                        renderGroup={AutocompleteGroupRender}
                        getOptionLabel={option => option.name}
                        onChange={(e, value) => { setHelpRef(value) }}
                        renderInput={(params) => <TextField {...params} label="Справка" />}
                    />
                </Stack>
            </Box>
            <Button sx={{ margin: '0 auto 36px' }}
                variant="contained"
                size="large"
                disabled={!name}
                onClick={handleCreate}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default QuestionsNew;