import { Box, Button, IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ISection, ISectionUpdate } from '../../API/interfaces/section.interface';
import showIcon from '../../assets/images/show.svg';
import deleteIcon from '../../assets/images/delete.svg';
import crossIcon from '../../assets/images/cross.svg';
import acceptIcon from '../../assets/images/plus.svg'
import ProgressBar from '@ramonak/react-progress-bar';
import '../../style/progressBar.style.css';

interface ISectionItemProps {
    section: ISection;
    onUpdate: (id: string, payload: ISectionUpdate) => Promise<void>;
    onDelete: (id: string) => Promise<void>;
}

const ModulesItem: FC<ISectionItemProps> = ({ section, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [name, setName] = useState<string>(section.name);
    const [order, setOrder] = useState<number>(section.order);
    const [successRequirement, setSuccessRequirement] = useState<number>(section.successRequirement);

    const resetValues = () => {
        setEditMode(false);
        setName(section.name);
        setOrder(section.order);
        setSuccessRequirement(section.successRequirement);
    }

    const handleUpdate = () => {
        onUpdate(section.id, {
            order,
            name,
            successRequirement
        }).then(() => setEditMode(false));
    }

    const handleDelete = () => onDelete(section.id);

    const disableValues = !(name && order && successRequirement)

    const successRequirementString = String(section.successRequirement)

    const questions = section._count.questions;

    const maxCompletedCheck =
        questions === 0 ?
            (successRequirement * 2)
            :
            (successRequirement === 1 && questions > 9) ?
                11
                :
                (successRequirement === 2 && questions > 20 && questions < 120) ?
                19
                :
                (successRequirement - questions > questions) ?
                    (successRequirement * 2)
                    :
                    questions < successRequirement ?
                        (successRequirement + questions)
                        :
                        (questions - successRequirement > 50) ?
                            (questions / 8)
                            :
                            (questions / 1.5)

    const questionsCountVisible = section.successRequirement === questions ? 'none' : 'block'

    const isEqual = section.successRequirement === questions ? true : false

    if (isEditMode) return (
        <TableRow sx={{ verticalAlign: 'top', background: '#018DCA33' }}>
            <TableCell align="center">
                <TextField fullWidth
                    className="block"
                    type="number"
                    sx={{ background: '#fff', borderRadius: '7px', width: '55px', margin: '0 auto' }}
                    variant="outlined"
                    size='small'
                    value={order}
                    onChange={(e) => setOrder(Number(e.target.value))}
                    required={true}
                />
            </TableCell>
            <TableCell align="center">
                <TextField fullWidth
                    className="block"
                    type="text"
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    variant="outlined"
                    size='small'
                    multiline
                    maxRows={3}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required={true}
                />
            </TableCell>
            <TableCell align="center">
                <TextField fullWidth
                    className="block"
                    type="number"
                    sx={{ background: '#fff', borderRadius: '7px', width: '55px', margin: '0 auto' }}
                    variant="outlined"
                    size='small'
                    value={successRequirement}
                    onChange={(e) => setSuccessRequirement(Number(e.target.value))}
                    required={true}
                />
            </TableCell>
            <TableCell align="center">
                <Link to={`/modules/${section.id}/questions`}>
                    <Button variant='outlined'>{'Добавить'}</Button>
                </Link>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={resetValues}><img src={crossIcon} alt="X" /></IconButton>
                <IconButton onClick={handleUpdate} disabled={disableValues}
                    sx={{ opacity: disableValues ? '.6' : '1' }}
                >
                    <img src={acceptIcon} alt="✓" />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    return (
        <TableRow sx={{ height: '70px' }}>
            <TableCell align="center" >{section.order}</TableCell>
            <TableCell align="center" sx={{ height: '70px', width: '500px' }}>
                <p className='ellipsis' style={{ maxWidth: '600px' }}>
                    {section.name}
                </p>
            </TableCell>
            <TableCell align="center" >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70px', alignSelf: 'center' }}>
                    <ProgressBar
                        completed={successRequirementString}
                        maxCompleted={maxCompletedCheck}
                        width={isEqual ? '155px' : '110px'} height='31'
                        bgColor='#018DCA' borderRadius='6px'
                        labelAlignment='center'
                        className="wrapper"
                    />
                    <span
                        className='span'
                        style={{
                            position: 'relative',
                            right: 5,
                            fontSize: '16px',
                            display: questionsCountVisible,
                            background: '#e0e0de', paddingTop: '3.5px',
                            height: '31.45px', width: '45px',
                            borderRadius: '0 6px 6px 0'
                        }}>
                        {questions}
                    </span>
                </Box>
            </TableCell>
            <TableCell align="center">
                <Link to={`/modules/${section.id}/questions`}>
                    <Button variant='outlined'>{questions}</Button>
                </Link>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(true)}> <img src={showIcon} alt="показать" /></IconButton>
                <IconButton onClick={handleDelete}><img src={deleteIcon} alt="удалить" /></IconButton>
            </TableCell>
        </TableRow>
    )
}

export default ModulesItem;